import styled from "styled-components";

const Span = styled.span``;

const SpanStyled = styled.span`
    font-weight: bold;
`;

export const textoSuporte = {
    titulo: "Está disponível suporte técnico ao adquirir o sistema?",
    corpo: {
        0: {
            tipo: "p",
            texto:
                "Sim! Oferecemos suporte técnico completo com modalidades que se ajustam às necessidades do seu negócio. O atendimento de suporte ao sistema por telefone é contratável, e você pode escolher entre duas opções:"
        },
        1: {
            tipo: "li",
            texto: [
                <Span><SpanStyled>Suporte Premium:</SpanStyled> Cobre o atendimento durante o horário comercial, de segunda a sexta-feira, das 07h às 19h, e aos sábados, das 07h às 13h.</Span>,
                <Span><SpanStyled>Suporte Plantão:</SpanStyled> Oferece atendimento fora do horário comercial, de segunda a sexta-feira, das 19h às 00h, aos sábados, das 13h às 00h, e aos domingos, das 07h às 18h.</Span>
            ]
        },
        2: {
            tipo: "p",
            texto:
                "Além disso, oferecemos suporte via WhatsApp de segunda a sexta-feira, das 08h às 17h30, para maior comodidade. Nossos consultores estão prontos para te auxiliar em qualquer momento, de acordo com o plano contratado."
        }
    }
}