import { textoSuporte } from "./TextosGerais";

export const TextosWaymenu = {
	0: {
		titulo: "Qual é o custo da ferramenta?",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"Os valores do Waymenu variam de acordo com o tipo de operação da sua empresa. Você pode usar o cardápio digital gratuitamente com as seguintes funcionalidades extras:"
			},
			1: {
				tipo: "li",
				texto: [
					"Produtos ilimitados",
					"Categorias com fotos",
					"Cardápios programáveis por horário",
					"Descrição completa de produtos com fotos",
					"2 opções de design de menu (reduzido ou expandido)",
					"Suporte via chat"
				]
			},
			2: {
				tipo: "p",
				texto:
					"Para aproveitar funções como Cupons de Desconto, Integração com PDV e até mesmo PEDIDOS SEM TAXAS, consulte a tabela de valores dos planos Waymenu abaixo."
			}
		}
	},
	1: textoSuporte,
	2: {
		titulo: "O sistema funciona apenas no celular?",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"Não! O Waymenu não precisa ser instalado no seu dispositivo para funcionar. Seu restaurante fica online através de um link da web, permitindo acesso a qualquer hora, de qualquer lugar e em qualquer dispositivo."
			}
		}
	},
	3: {
		titulo: "Consigo registrar pedidos recebidos no telefone?",
		corpo: {
			0: {
				tipo: "p",
				texto: "Sim! A versatilidade de tanto o cardápio digital quanto o retaguarda serem acessíveis por link da web permite que toda a administração possa ser feita de qualquer dispositivo, inclusive smartphones ou tablets."
			}
		}
	},
	4: {
		titulo:
			"O sistema faz impressão de comandas?",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"Sim! O Waymenu imprime comandas de delivery e cozinha, destinando os setores de impressão para os produtos."
			}
		}
	},
	5: {
		titulo:
			"O meu cliente faz o pagamento online?",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"Sim! Para restaurantes que utilizam a partir do plano premium, os pedidos podem ser solicitados e já pagos pelo cliente de maneira 100% online."
			}
		}
	}
};
