import styled from "styled-components";

const ContainerUnitario = styled.div`
	background-color: #fea106;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2em;
	border-radius: var(--border-radius-s);
	gap: 1em;
	justify-content: flex-end;

	@media screen and (min-width: 650px) {
		width: 45%;
	}
`;

const ContainerDuplo = styled.div`
    background-color: #fea106;
    display: flex;
    flex-direction: row;
    align-items: end;
    border-radius: var(--border-radius-s);
    gap: 1em;
    justify-content: space-between;
    width: 91.5%;

	@media screen and (max-width: 650px) {
		flex-direction: column;
		width: 100%;
		align-items: normal;
	}
`;

const Titulo = styled.h1`
	color: white;
	font-weight: var(--strong-weight);
	font-size: var(--big-font);
	text-align: center;
`;

const Valor = styled.span`
	color: #f05b51;
	background-color: white;
	padding: 0.5em 0;
	width: 100%;
	text-align: center;
	font-weight: var(--strong-weight);
	font-size: var(--bigger-font);
	border-radius: var(--border-radius-s);
`;

const Subtitulo = styled.h2`
	color: white;
	font-weight: var(--weak-weight);
	font-size: var(--smallest-font);
	text-align: center;
`;

const Ou = styled.span`
	color: white;
	align-self: center;
	font-weight: var(--strong-weight);
	font-size: var(--bigger-font);

	&::after {
    	content: "Ou";
	}
`;

export function CaixaUnitaria({ titulo, valor }) {
	return (
		<ContainerUnitario>
			<Titulo>{titulo}</Titulo>
			<Valor>{valor}</Valor>
			<Subtitulo>
				Acrescenta na mensalidade a partir do plano Plano Bronze.
			</Subtitulo>
		</ContainerUnitario>
	);
}

export function CaixaDupla({ titulo1, valor1, titulo2, valor2 }) {
	return (
		<ContainerDuplo>
			<CaixaUnitaria titulo={titulo1} valor={valor1} />
			<Ou />
			<CaixaUnitaria titulo={titulo2} valor={valor2} />
		</ContainerDuplo>
	);
}
