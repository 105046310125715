import styled from "styled-components";
import { Link } from "react-router-dom";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.8em;
	align-items: center;
    width: 8em;
`;

const Imagem = styled.img`
	width: 7em;
	height: auto;
	border-radius: var(--border-radius-circle);
	filter: drop-shadow(1px 1px 4px black);
`;

const Nome = styled.h1`
	font-weight: var(--strong-weight);
	font-size: var(--medium-font);
	color: white;
    margin: 0;
    text-align: center;
`;

const DetalhesContainer = styled.div``;

const CargoContainer = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
`;

const Cargo = styled.h2`
	color: white;
    margin: 0;
	font-weight: var(--weak-weight);
	font-size: var(--small-font);
`;

const Linkedin = styled(Link)`
	width: 3em;
	color: ${(props) => props.$color || "#88BD28"};
`;

export default function Circulo({ imagem, temDetalhes, nome, cargo, linkedinColor, url }) {
	// Extrair nome da imagem
	const arrayUrlImagem = imagem.split('/');
	const tamanho = arrayUrlImagem.length;
	const arrayNomeImagem = arrayUrlImagem[tamanho - 1];
	const nomeImagem = arrayNomeImagem.split('.')[0];

	return (
		<Container>
			<Link to={url}>
				<Imagem src={imagem} alt={`Imagem de ${nomeImagem}`} />
			</Link>

			{temDetalhes && (
				<>
					<DetalhesContainer>
						<Nome>{nome}</Nome>
						<CargoContainer>
							<Cargo>{cargo}</Cargo>
							<Linkedin className="bi bi-linkedin" $color={linkedinColor} to={url}/>
						</CargoContainer>
					</DetalhesContainer>
				</>
			)}
		</Container>
	);
}
