import styled from "styled-components";
import { useEffect } from "react";
// Componentes personalizados
import BlocoTituloTextoBotaoImagem from "../../components/BlocoTituloTextoBotaoImagem";
import { BotaoFaleConosco, BotaoReceberLigacao } from "../../components/Botoes";
import { PorqueProdutoLumien } from "../../components/PorqueProduto";
import { FuncoesLumien } from "../../components/Funcoes";
import { TituloFeaturesTextoLumien } from "../../components/TituloFeaturesTexto";
import { DuvidasLumien } from "../../components/Duvidas";
import Form from "../../components/Form";
import SecaoPagina from "../../components/SecaoPagina";
import PageLayout from "../../layouts/PageLayout";
// Imagens
import FundoHeader from "../../assets/imagens/lumien/header.webp";
import FundoFuncoes from "../../assets/imagens/lumien/fundo-funcoes.webp";
import FundoDuvidas from "../../assets/imagens/lumien/fundo-duvidas.webp";
import TelasAplicacao from "../../assets/imagens/lumien/Lumien - Homepage.webp";
import LogoProduto from "../../assets/imagens/logos-produtos/Lumien - Branco.svg";

const Logo = styled.img`
	width: 4em;
	height: auto;

	@media screen and (min-width: 993px) {
		margin-right: auto;
	}
`;

export default function PageLumien() {
	useEffect(() => {
		require("bootstrap/dist/js/bootstrap.min.js");
	}, []);

	return (
		<PageLayout>
			<SecaoPagina
				$paddingmin={"2em"}
				$bgimagem={FundoHeader}
				$position={"left"}
			>
				<Logo src={LogoProduto} alt={"Logo Lumien"} />

				<BlocoTituloTextoBotaoImagem
					imagem={TelasAplicacao}
					max={"30em"}
					margemImagem={null}
					corTitulo={null}
					paragrafo2={null}
					tituloTipoA={null}
					titulo2={null}
					possuiLogosClientes={false}
					botao1={<BotaoFaleConosco />}
					botao2={<BotaoReceberLigacao />}
					corParagrafo={"white"}
					paragrafo1={
						"Aumente a eficiência do seu posto de combustível com nosso sistema integrado de automação, controle e gestão financeira. Aproveite também a versatilidade de um PDV na SmartPOS com o Lumien Pay!"
					}
					tituloTipoB={true}
					titulo1={
						"Lumien: Sistema de Gestão Completo para Postos de Combustíveis"
					}
				/>
			</SecaoPagina>

			<SecaoPagina $bgcolor={"#EFEFEF"} $paddingmin={"2em"}>
				<TituloFeaturesTextoLumien />
			</SecaoPagina>

			<SecaoPagina $bgcolor={"#EFEFEF"} $paddingmin={"2em"}>
				<PorqueProdutoLumien />
			</SecaoPagina>

			<SecaoPagina $bgimagem={FundoFuncoes} $paddingmin={"2em"}>
				<FuncoesLumien />
			</SecaoPagina>

			<SecaoPagina $bgimagem={FundoDuvidas} $paddingmin={"2em"}>
				<DuvidasLumien />
			</SecaoPagina>

			<SecaoPagina
				$bg={"#EFEFEF"}
				$paddingmin={"2em 2em 9em"}
				$paddingmid={"2em 2em 9em"}
				$paddingmax={"4em 4em 8em"}
				id={"formLigacao"}
			>
				<Form
					formProduto={true}
					titulo={"Nosso time liga para você!"}
					subtitulo={
						"Pronto para começar? Preencha o formulário abaixo e o nosso time de consultores entrará em contato com você."
					}
					trabalheConosco={false}
					color={"black"}
					$bgcolor={"#4ea32a"}
				/>
			</SecaoPagina>
		</PageLayout>
	);
}
