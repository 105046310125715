import styled from "styled-components";
import { useEffect } from "react";
// Componentes personalizados
import BlocoTituloTextoBotaoImagem from "../../components/BlocoTituloTextoBotaoImagem";
import { BotaoFaleConosco, BotaoReceberLigacao } from "../../components/Botoes";
import { PorqueProdutoWaychef } from "../../components/PorqueProduto";
import { FuncoesWaychef } from "../../components/Funcoes";
import { TituloFeaturesTextoWaychef } from "../../components/TituloFeaturesTexto";
import { DuvidasWaychef } from "../../components/Duvidas";
import PlanosPrecosWaychef from "../../components/PlanosPrecosWaychef";
import CaixasPrecosWaychef from "../../components/CaixasPrecosWaychef";
import Form from "../../components/Form";
import SecaoPagina from "../../components/SecaoPagina";
import PageLayout from "../../layouts/PageLayout";
// Imagens
import FundoLaranjaWaychefTelas from "../../assets/imagens/FundoLaranjaWaychefTelas.webp";
import FundoLaranjaDiner from "../../assets/imagens/waychef/FundoLaranjaDiner.jpg";
import Rei from "../../assets/imagens/waychef/Rei.webp";
import FundoLaranja from "../../assets/imagens/waychef/FundoLaranjaS.jpg";
import TelasAplicacao from "../../assets/imagens/waychef/TelasAplicacao.webp";
import LogoProduto from "../../assets/imagens/logos-produtos/Waychef - Branco.svg";

const Logo = styled.img`
	width: 10em;
	height: auto;

	@media screen and (min-width: 993px) {
		margin-right: auto;
	}
`;

export default function PageWaychef() {
	useEffect(() => {
		require("bootstrap/dist/js/bootstrap.min.js");
	}, []);

	return (
		<PageLayout>
			<SecaoPagina
				$paddingmin={"2em"}
				$bgimagem={FundoLaranjaWaychefTelas}
				$position={"left"}
			>
				<Logo src={LogoProduto} alt={"Logo Waychef"} />

				<BlocoTituloTextoBotaoImagem
					imagem={TelasAplicacao}
					max={"30em"}
					margemImagem={null}
					corTitulo={null}
					paragrafo2={null}
					tituloTipoA={null}
					titulo2={null}
					possuiLogosClientes={false}
					botao1={<BotaoFaleConosco />}
					botao2={<BotaoReceberLigacao />}
					corParagrafo={"white"}
					paragrafo1={
						"Com o Waychef o seu negócio ganha na praticidade! Conheça o sistema que te dá controle financeiro total, acompanha seus pedidos por mesa/comanda e mais."
					}
					tituloTipoB={true}
					titulo1={
						"O Sistema de gestão para restaurantes que cabe no seu bolso"
					}
				/>
			</SecaoPagina>

			<SecaoPagina $bgcolor={"#EFEFEF"} $paddingmin={"2em"}>
				<TituloFeaturesTextoWaychef />
			</SecaoPagina>

			<SecaoPagina $bgcolor={"#EFEFEF"} $paddingmin={"2em"}>
				<PorqueProdutoWaychef />
			</SecaoPagina>

			<SecaoPagina $bgimagem={FundoLaranjaDiner} $paddingmin={"2em"}>
				<FuncoesWaychef />
			</SecaoPagina>

			<SecaoPagina
				$bgcolor={"#EFEFEF"}
				$paddingmin={"2em 2em 0"}
				$paddingmid={"2em 2em 0"}
				$paddingmax={"4em 4em 0"}
			>
				<BlocoTituloTextoBotaoImagem
					paddingTextContainer={"0 0 3em"}
					imageAlignment={"end"}
					corParagrafo={null}
					tituloTipoB={null}
					titulo2={null}
					imagem={Rei}
					margemImagem={"0"}
					possuiLogosClientes={false}
					botao1={null}
					botao2={null}
					paragrafo1={
						"Nós conhecemos os principais desafios que uma franquia de Food Service possui. No Waychef você controla o desde o Mix de produtos até a política de promoções e de preços das suas unidades."
					}
					paragrafo2={
						"A franqueadora também conta com um dashboard que centraliza os dados de eficiência operacional dos seus franqueados!"
					}
					tituloTipoA={true}
					titulo1={"Nosso sistema de gestão é otimizado para o Franchising"}
					corTitulo={"#f26a35"}
				/>
			</SecaoPagina>

			<SecaoPagina $bgimagem={FundoLaranja} $paddingmin={"2em"}>
				<DuvidasWaychef />
			</SecaoPagina>

			<SecaoPagina
				$bgcolor={"#EFEFEF"}
				$paddingmin={"2em 0.4em"}
				$paddingmid={"2em 1em"}
				id={"tabela-precos"}
			>
				<PlanosPrecosWaychef />
			</SecaoPagina>

			<SecaoPagina
				$bg={"#F26A35"}
				$paddingmin={"2em"}
				$paddingmid={"2em"}
				$paddingmax={"4em"}
			>
				<CaixasPrecosWaychef />
			</SecaoPagina>
			<SecaoPagina
				$bg={"#EFEFEF"}
				$paddingmin={"2em 2em 9em"}
				$paddingmid={"2em 2em 9em"}
				$paddingmax={"4em 4em 8em"}
				id={"formLigacao"}
			>
				<Form
					formProduto={true}
					titulo={"Nosso time liga para você!"}
					subtitulo={
						"Pronto para começar? Preencha o formulário abaixo e o nosso time de consultores entrará em contato com você."
					}
					trabalheConosco={false}
					color={"black"}
					$bgcolor={"#F26A35"}
				/>
			</SecaoPagina>
		</PageLayout>
	);
}
