// Componentes personalizados
import Form from "../../components/Form";
import BannerNavegacao from "../../components/BannerNavegacao";
import BlocoTitulo from "../../components/BlocoTituloTextoBotaoImagem";
import { BotaoEnviarCurriculo } from "../../components/Botoes";
import SecaoPagina from "../../components/SecaoPagina";
import PageLayout from "../../layouts/PageLayout";
// Imagens
import ImagemPessoasMontandoLego from "../../assets/imagens/PessoasMontandoLego.svg";
import FundoVerde from "../../assets/imagens/FundoVerdeGrafo.webp";

export default function PaginaTrabalheConosco() {
	return (
		<PageLayout>
			<BannerNavegacao pagina={"Trabalhe conosco"} />

			<SecaoPagina>
				<BlocoTitulo
					margemImagem={null}
					botao2={null}
					corParagrafo={null}
					corTitulo={null}
					tituloTipoB={false}
					imagem={ImagemPessoasMontandoLego}
					max={"30em"}
					possuiLogosClientes={false}
					botao1={<BotaoEnviarCurriculo />}
					paragrafo1={
						"Na Sifat, valorizamos talentos e estamos comprometidos em proporcionar um ambiente acolhedor que estimule o seu crescimento e desenvolvimento profissional. "
					}
					paragrafo2={
						"Seja você um especialista em Tecnologia da Informação (TI), Administração ou Consultor de Vendas e Negócios, estamos de braços abertos para recebê-lo em nossa equipe."
					}
					tituloTipoA={true}
					titulo1={"Venha fazer parte da"}
					titulo2={"equipe Sifat!"}
				/>
			</SecaoPagina>

			<SecaoPagina
				$bgimagem={FundoVerde}
				$paddingmin={"2em 2em 9em"}
				$paddingmid={"2em 2em 9em"}
				$paddingmax={"4em 4em 8em"}
				id={"formTrabalhe"}
			>
				<Form
					titulo={"Trabalhe conosco"}
					subtitulo={
						"Não perca tempo! Cadastre-se agora e envie seu currículo através do formulário ao lado. Junte-se à Equipe Sifat e faça parte de uma jornada de sucesso e realização profissional."
					}
					color={"white"}
					trabalheConosco={true}
				/>
			</SecaoPagina>
		</PageLayout>
	);
}
