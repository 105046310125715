import { useEffect } from "react";
import {
	createBrowserRouter,
	RouterProvider,
	ScrollRestoration,
	Outlet,
} from "react-router-dom";
import PageInicial from "./pages/home";
import PageSejaUmParceiro from "./pages/seja-parceiro";
import PageFaleConosco from "./pages/fale-conosco";
import PageTrabalheConosco from "./pages/trabalhe-conosco";
import PageSobreSifat from "./pages/sobre-sifat";
import PageSobreWaybe from "./pages/sobre-waybe";
import PageSobreProdati from "./pages/sobre-prodati";
import PageWaychef from "./pages/waychef";
import PageEfex from "./pages/efex";
import PageFlutt from "./pages/flutt";
import PageLumien from "./pages/lumien";
import PageWaymenu from "./pages/waymenu";
import PageWaypay from "./pages/waypay";
import PageWayservice from "./pages/wayservice";
import PagePrivacidade from "./pages/privacidade";
import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init();

const AppLayout = () => (
	<>
		<ScrollRestoration />
		<Outlet />
	</>
);

const router = createBrowserRouter([
	{
		element: <AppLayout />,
		children: [
			{
				path: "/",
				element: <PageInicial />,
			},
			{
				path: "/trabalhe-conosco",
				element: <PageTrabalheConosco />,
			},
			{
				path: "/fale-conosco",
				element: <PageFaleConosco />,
			},
			{
				path: "/integrador",
				element: <PageSejaUmParceiro />,
			},
			{
				path: "/quem-somos",
				element: <PageSobreWaybe />,
			},
			{
				path: "/quem-somos-prodati",
				element: <PageSobreProdati />,
			},
			{
				path: "/quem-somos-sifat",
				element: <PageSobreSifat />,
			},
			{
				path: "/waychef",
				element: <PageWaychef />,
			},
			{
				path: "/efex-pdv",
				element: <PageEfex />,
			},
			{
				path: "/sifat-flutt",
				element: <PageFlutt />,
			},
			{
				path: "/sifat-lumien",
				element: <PageLumien />,
			},
			{
				path: "/waymenu",
				element: <PageWaymenu />,
			},
			{
				path: "/politica-de-privacidade",
				element: <PagePrivacidade />,
			},
			{
				path: "/waypay",
				element: <PageWaypay />,
			},
			{
				path: "/wayservice",
				element: <PageWayservice />,
			},
		],
	},
]);

function App() {
	useEffect(() => {
		document.title = "Sifat Sistemas - Sistema para automação comercial";
	}, []);

	return (
		<>
			<RouterProvider router={router} />
		</>
	);
}

export default App;
