import styled from "styled-components";
import { useEffect } from "react";
// Componentes personalizados
import BlocoTituloTextoBotaoImagem from "../../components/BlocoTituloTextoBotaoImagem";
import { BotaoFaleConosco, BotaoReceberLigacao } from "../../components/Botoes";
import { PorqueProdutoWaypay } from "../../components/PorqueProduto";
import { VideoDemonstrativoWaypay } from "../../components/VideoDemonstrativo";
import { TituloFeaturesTextoWaypay } from "../../components/TituloFeaturesTexto";
import { DuvidasWaypay } from "../../components/Duvidas";
import Form from "../../components/Form";
import SecaoPagina from "../../components/SecaoPagina";
import PageLayout from "../../layouts/PageLayout";
// Imagens
import Header from "../../assets/imagens/waypay/Header.webp";
import FundoVideo from "../../assets/imagens/waypay/fundo_video.webp";
import PerguntasWaypay from "../../assets/imagens/waypay/fundo_perguntas.webp";
import TelasAplicacao from "../../assets/imagens/waypay/TelasAplicacao.webp";
import LogoProduto from "../../assets/imagens/logos-produtos/Waypay - Branco.svg";

const Logo = styled.img`
	width: 10em;
	height: auto;

	@media screen and (min-width: 993px) {
		margin-right: auto;
	}
`;

export default function PageWaypay() {
	useEffect(() => {
		require("bootstrap/dist/js/bootstrap.min.js");
	}, []);

	return (
		<PageLayout>
			<SecaoPagina
				$paddingmin={"2em"}
				$bgimagem={Header}
				$position={"left"}
			>
				<Logo src={LogoProduto} alt={"Logo Waypay"} />

				<BlocoTituloTextoBotaoImagem
					imagem={TelasAplicacao}
					max={"30em"}
					margemImagem={null}
					corTitulo={null}
					paragrafo2={null}
					tituloTipoA={null}
					titulo2={null}
					possuiLogosClientes={false}
					botao1={<BotaoFaleConosco />}
					botao2={<BotaoReceberLigacao />}
					corParagrafo={"white"}
					paragrafo1={
						"Conheça o Waypay, a solução da Sifat Sistemas que vai reduzir as filas do seu negócio e aumentar a taxa de fidelização dos seus clientes."
					}
					tituloTipoB={true}
					titulo1={
						"Software de autopagamento para Bares e Conveniências"
					}
				/>
			</SecaoPagina>

			<SecaoPagina $bgcolor={"#EFEFEF"} $paddingmin={"2em"}>
				<TituloFeaturesTextoWaypay />
			</SecaoPagina>

			<SecaoPagina $bgcolor={"#EFEFEF"} $paddingmin={"2em"}>
				<PorqueProdutoWaypay />
			</SecaoPagina>

			<SecaoPagina $bgimagem={FundoVideo} $bgcolor={"#EFEFEF"} $paddingmin={"2em"}>
				<VideoDemonstrativoWaypay />
			</SecaoPagina>

			<SecaoPagina $bgimagem={PerguntasWaypay} $paddingmin={"2em"}>
				<DuvidasWaypay />
			</SecaoPagina>

			<SecaoPagina
				$bg={"#EFEFEF"}
				$paddingmin={"2em 2em 9em"}
				$paddingmid={"2em 2em 9em"}
				$paddingmax={"4em 4em 8em"}
				id={"formLigacao"}
			>
				<Form
					formProduto={true}
					titulo={"Nosso time liga para você!"}
					subtitulo={
						"Pronto para começar? Preencha o formulário abaixo e o nosso time de consultores entrará em contato com você."
					}
					trabalheConosco={false}
					color={"black"}
					$bgcolor={"#2895C9"}
				/>
			</SecaoPagina>
		</PageLayout>
	);
}
