import styled from "styled-components";
import { BotaoEnviar } from "../Botoes";

const FormContainer = styled.form`
	display: flex;
	flex-direction: column;
	font-size: var(--smaller-font);
	font-weight: var(--regular-weight);
	display: flex;
	flex-direction: column;
	padding: 2em 2em;
	gap: 0.6em;
	background-color: ${(props) => props.$bgcolor || "rgb(15, 50, 100)"};
	border-radius: var(--border-radius-s);
	width: 100%;

	@media screen and (min-width: 650px) {
		width: 65vw;
		max-width: 600px;
	}
`;

const InputLabel = styled.label`
	padding: 0 0 0.2em 0.7em;
	color: white;
	text-align: center;
	width: 100%;
`;

const Campo = styled.input`
	width: 100%;
	border: none;
	border-radius: 5px;
	padding: 0.4em;
	color: ${(props) => props.$color || "black"};
	background-color: ${(props) => props.$color || "white"};
`;

const TextArea = styled.textarea`
	width: 100%;
	border: none;
	border-radius: 5px;
	padding: 0.4em;
	color: ${(props) => props.$color || "black"};
	background-color: ${(props) => props.$color || "white"};
`;

const Title = styled.h1`
	font-weight: var(--strong-weight);
	font-size: var(--bigger-font);
	text-align: left;
	color: ${(props) => props.$color || "black"};
`;

const Subtitle = styled.h2`
	text-align: ${(props) => props.$align || "center"};
	font-weight: var(--weak-weight);
	font-size: ${(props) => props.$size || "var(--small-font)"};
	color: ${(props) => props.$color || "black"};
`;

const Espacador = styled.div`
	width: 100%;
	height: 1em;
	background: transparent;
`;

export default function Form({
	titulo,
	subtitulo,
	sejaParceiro,
	formProduto,
	color,
	$bgcolor,
	trabalheConosco
}) {
	return (
		<>
			<Title $color={color}>{titulo}</Title>
			<Subtitle $color={color}>{subtitulo}</Subtitle>

			<FormContainer $bgcolor={$bgcolor}>
				<div>
					<InputLabel htmlFor="nomeUsuario">Nome: <span style={{color: "red"}}>*</span></InputLabel>
					<Campo
						type="text"
						name="nomeUsuario"
						id="nomeUsuario"
						placeholder="Como gostaria de ser chamado?"
					/>
				</div>

				{formProduto && (
					<>
						<div>
							<InputLabel htmlFor="nomeEmpresa">Nome da empresa: <span style={{color: "red"}}>*</span></InputLabel>
							<Campo
								type="text"
								name="nomeEmpresa"
								id="nomeEmpresa"
								placeholder="Qual o nome da empresa?"
							/>
						</div>
					</>
				)}

				<div>
					<InputLabel htmlFor="email">E-mail: <span style={{color: "red"}}>*</span></InputLabel>
					<Campo
						type="text"
						name="email"
						id="email"
						placeholder="Ex.: nome@gmail.com"
					/>
				</div>
				<div>
					<InputLabel htmlFor="telefone">WhatsApp: <span style={{color: "red"}}>*</span></InputLabel>
					<Campo
						type="text"
						name="telefone"
						id="telefone"
						placeholder="Ex.: (17) 00000-0000"
					/>
				</div>

				{sejaParceiro && (
					<>
						<Espacador />

						<Subtitle
							$color={"white"}
							$align={"center"}
							$size={"var(--medium-font)"}
						>
							Dados da empresa
						</Subtitle>
						<div>
							<InputLabel htmlFor="cnpj">CNPJ:</InputLabel>
							<Campo
								type="text"
								name="cnpj"
								id="cnpj"
								placeholder="Ex.: 00.000.000/0000-00"
							/>
						</div>
						<div>
							<InputLabel htmlFor="cnae">CNAE:</InputLabel>
							<Campo
								type="text"
								name="cnae"
								id="cnae"
								placeholder="Digite seu CNAE"
							/>
						</div>
						<div>
							<InputLabel htmlFor="razaoSocial">Razão Social:</InputLabel>
							<Campo
								type="text"
								name="razaoSocial"
								id="razaoSocial"
								placeholder="Digite sua Razão Social"
							/>
						</div>
					</>
				)}

				{!trabalheConosco && (
					<>
						<div>
							<InputLabel htmlFor="estado">Estado <span style={{color: "red"}}>*</span></InputLabel>
							<Campo
								type="text"
								name="estado"
								id="estado"
								placeholder="Ex.: São Paulo"
							/>
						</div>
						<div>
							<InputLabel htmlFor="cidade">Cidade <span style={{color: "red"}}>*</span></InputLabel>
							<Campo
								type="text"
								name="cidade"
								id="cidade"
								placeholder="Ex.: São José do Rio Preto"
							/>
						</div>
					</>
				)}
				
				{trabalheConosco && (
					<>
						<div>
							<InputLabel htmlFor="anexo">Anexar currículo <span style={{color: "red"}}>*</span></InputLabel>
							<Campo
								type="file"
								name="anexo"
								id="anexo"
							/>
						</div>
						<div>
							<InputLabel htmlFor="mensagem">Mensagem</InputLabel>
							<TextArea
								name="mensagem"
								id="mensagem"
								placeholder="Digite aqui sua mensagem."
								backgroundColor="white"
								rows="4"
							/>
						</div>
					</>
				)}

				{sejaParceiro && (
					<>
						<InputLabel htmlFor="arquivo">
							Caso queira se antecipar enviando documentos da empresa, clique
							abaixo.
						</InputLabel>
						<Campo $color={"white"} type="file" name="arquivo" id="arquivo" />
					</>
				)}

				<InputLabel htmlFor="receberEmail">
					<input
						type="checkbox"
						name="receberEmail"
						id="receberEmail"
						value="true"
						style={{ margin: "0 0.5em 0 0" }}
					/>
					Quero receber e-mails e mensagens sobre produtos e novidades da SIFAT.
				</InputLabel>

				<InputLabel>Campos com asterisco (<span style={{color: "red"}}>*</span>) são obrigatórios!</InputLabel>

				<Espacador />
				<BotaoEnviar />
			</FormContainer>
		</>
	);
}
