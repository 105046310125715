import styled from "styled-components";
import LogoWaymenu from "../../assets/imagens/logos-produtos/Waymenu - Branco.svg";
import { BotaoInscrevaWaymenu } from "../Botoes";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.5em;
	width: 100%;
`;

const Titulo = styled.h1`
	text-align: center;
	color: #f09400;
	font-weight: var(--strong-weight);
	font-size: var(--bigger-font);
`;

const TabelaWrapper = styled.div`
	width: 100%;
	height: auto;
`;

const Tabela = styled.table`
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
`;

const CorpoTabela = styled.tbody`
	width: 100%;
`;

const TituloTabela = styled.th`
	background-color: ${(props) => props.$bgColor || "transparent"};
	color: ${(props) => props.$color || "black"};
	text-align: center;
	font-weight: ${(props) => props.$weight || "var(--strong-weight)"};
	font-size: 2.5vw;
	word-wrap: break-word;
	width: 20%;
	padding: 1em 0;

	@media screen and (min-width: 600px) {
		font-size: var(--smaller-font);
	}
`;

const LinhaTabela = styled.tr`
	width: 100%;
`;

const DadoTabela = styled.td`
	background-color: ${(props) => props.$bgColor || "transparent"};
	color: ${(props) => props.$color || "black"};
	text-align: center;
	font-size: 2.5vw;
	word-wrap: break-word;
	width: 20%;
	padding: 1em 0;

	@media screen and (min-width: 600px) {
		font-size: var(--smaller-font);
	}
`;

const Logo = styled.img`
	height: auto;
	width: 6em;
`;

function Custo({ preco, tempo }) {
	return (
		<>
			<span style={{ fontSize: "var(--bigger-font)" }}>R${preco} </span>
			<span style={{ fontSize: "var(--smaller-font)" }}>{tempo}</span>
		</>
	);
}

function GerarIconeDadoTabela(tipo, quantidade) {
	if (tipo === "x") {
		return DesenharX(quantidade);
	} else if (tipo === "check") {
		return DesenharCheck(quantidade);
	} else if (tipo === "ilim") {
		return EscreverIlimitado(quantidade);
	}
}

function EscreverIlimitado(quantidade) {
	let lista = [];

	for (let i = 0; i < quantidade; i++) {
		lista.push(<DadoTabela key={i}>ILIMITADO</DadoTabela>);
	}

	return lista;
}

function DesenharX(quantidade) {
	let lista = [];

	for (let i = 0; i < quantidade; i++) {
		lista.push(
			<DadoTabela key={i}>
				<i
					style={{ color: "red", fontSize: "var(--biggest-font)" }}
					className="bi bi-x-lg"
				/>
			</DadoTabela>
		);
	}

	return lista;
}

function DesenharCheck(quantidade) {
	let lista = [];

	for (let i = 0; i < quantidade; i++) {
		lista.push(
			<DadoTabela key={i}>
				<i
					style={{ color: "green", fontSize: "var(--biggest-font)" }}
					className="bi bi-check-lg"
				/>
			</DadoTabela>
		);
	}

	return lista;
}

export default function PlanosPrecosWaymenu() {
	return (
		<Container>
			<Titulo>Planos e Preços</Titulo>
			<TabelaWrapper>
				<Tabela>
					<CorpoTabela>
						<LinhaTabela>
							<TituloTabela $bgColor={"#f09400"}>
								<Logo src={LogoWaymenu} alt={"Logo Waymenu"} />
							</TituloTabela>
							<TituloTabela $bgColor={"#f09400"} $color={"white"}>
								PLUS
							</TituloTabela>
							<TituloTabela $bgColor={"#f09400"} $color={"white"}>
								PREMIUM
							</TituloTabela>
							<TituloTabela $bgColor={"#f09400"} $color={"white"}>
								PLATINUM
							</TituloTabela>
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#f09400"} $color={"white"}>
								Plano Mensal
							</TituloTabela>
							<DadoTabela $bgColor={"#f09400"} $color={"white"}>
								<Custo preco={"0"} tempo={"por mês"} />
							</DadoTabela>
							<DadoTabela $bgColor={"#f09400"} $color={"white"}>
								<Custo preco={"65,00"} tempo={"por mês"} />
							</DadoTabela>
							<DadoTabela $bgColor={"#f09400"} $color={"white"}>
								<Custo preco={"249,00"} tempo={"por mês"} />
							</DadoTabela>
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Produtos Ilimitados
							</TituloTabela>
							{GerarIconeDadoTabela("check", 3)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Categorias com fotos para facilitar
							</TituloTabela>
							{GerarIconeDadoTabela("check", 3)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Cardápios Programáveis por Horário
							</TituloTabela>
							{GerarIconeDadoTabela("check", 3)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Descrição Completa de Produtos com Fotos
							</TituloTabela>
							{GerarIconeDadoTabela("check", 3)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								2 Opções de Layout (Reduzido ou Expandido)
							</TituloTabela>
							{GerarIconeDadoTabela("check", 3)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Suporte Via Chat
							</TituloTabela>
							{GerarIconeDadoTabela("check", 3)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Cupons de Desconto com Influencers
							</TituloTabela>
							{GerarIconeDadoTabela("x", 1)}
							{GerarIconeDadoTabela("check", 2)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Pedidos no Delivery com Integração PDV
							</TituloTabela>
							{GerarIconeDadoTabela("x", 1)}
							{GerarIconeDadoTabela("check", 2)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Taxa de Entrega por Raio e Bairro
							</TituloTabela>
							{GerarIconeDadoTabela("x", 1)}
							{GerarIconeDadoTabela("check", 2)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Pagamento Online (Rede)
							</TituloTabela>
							{GerarIconeDadoTabela("x", 1)}
							{GerarIconeDadoTabela("check", 2)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Pedidos Ilimitados
							</TituloTabela>
							{GerarIconeDadoTabela("x", 1)}
							{GerarIconeDadoTabela("check", 2)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Pedidos na Mesa com Integração no PDV*<br/>
								*Verificar disponibilidade
							</TituloTabela>
							{GerarIconeDadoTabela("x", 1)}
							<DadoTabela>Bônus por Tempo Limitado</DadoTabela>
							{GerarIconeDadoTabela("check", 1)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Sem taxa por pedido
							</TituloTabela>
							{GerarIconeDadoTabela("x", 1)}
							<DadoTabela>R$ 0,25 por pedido</DadoTabela>
							{GerarIconeDadoTabela("check", 1)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"} />
							
							<DadoTabela>
								<Container>
									<BotaoInscrevaWaymenu />
								</Container>
							</DadoTabela>
							
							<DadoTabela>
								<Container>
									<BotaoInscrevaWaymenu />
								</Container>
							</DadoTabela>
							
							<DadoTabela>
								<Container>
									<BotaoInscrevaWaymenu />
								</Container>
							</DadoTabela>
						</LinhaTabela>
					</CorpoTabela>
				</Tabela>
			</TabelaWrapper>
		</Container>
	);
}
