// Icones das funcoes
import Cadeiras from "../../assets/imagens/waychef/Cadeiras.svg";
import Caixas from "../../assets/imagens/waychef/Caixas.svg";
import Calendario from "../../assets/imagens/waychef/Calendario.svg";
import Carrinho from "../../assets/imagens/waychef/Carrinho.svg";
import Cartoes from "../../assets/imagens/waychef/Cartoes.svg";
import Conta from "../../assets/imagens/waychef/Conta.svg";
import Grafico from "../../assets/imagens/waychef/Grafico.svg";
import Graficos from "../../assets/imagens/waychef/Graficos.svg";
import Homem from "../../assets/imagens/waychef/Homem.svg";
import Impressao from "../../assets/imagens/waychef/Impressao.svg";
import Mao from "../../assets/imagens/waychef/Mao.svg";
import Monitor from "../../assets/imagens/waychef/Monitor.svg";
import Nuvem from "../../assets/imagens/waychef/Nuvem.svg";
import Papel from "../../assets/imagens/waychef/Papel.svg";
import Planilha from "../../assets/imagens/waychef/Planilha.svg";
import Recibo from "../../assets/imagens/waychef/Recibo.svg";
import Registradora from "../../assets/imagens/waychef/Registradora.svg";
import Regua from "../../assets/imagens/waychef/Regua.svg";

const conteudoWaychef = [
	{
		Imagem: Graficos,
		Texto: "Relatórios e dashboards gerenciais",
		Alt: "Graficos",
	},
	{ Imagem: Recibo, Texto: "Contas a pagar e a receber", Alt: "Recibo" },
	{ Imagem: Nuvem, Texto: "ERP em nuvem", Alt: "Nuvem" },
	{
		Imagem: Impressao,
		Texto: " Múltiplos locais de produção (impressoras)",
		Alt: "Impressao",
	},
	{ Imagem: Planilha, Texto: "Crédito na fatura", Alt: "Planilha" },
	{
		Imagem: Calendario,
		Texto: "Controle de vendas a prazo",
		Alt: "Calendario",
	},
	{ Imagem: Carrinho, Texto: "Venda de produtos", Alt: "Carrinho" },
	{ Imagem: Papel, Texto: "Emissão de NFe", Alt: "Papel" },
	{ Imagem: Cartoes, Texto: "Controle de cartões", Alt: "Cartoes" },
	{ Imagem: Cadeiras, Texto: "Controle de mesas e fichas", Alt: "Cadeiras" },
	{ Imagem: Regua, Texto: "Variação de produtos", Alt: "Regua" },
	{ Imagem: Caixas, Texto: "Múltiplos locais de estocagem", Alt: "Caixas" },
	{ Imagem: Mao, Texto: "Cadastro de Produtos", Alt: "Mao" },
	{
		Imagem: Registradora,
		Texto: "Controle e conferência de caixa",
		Alt: "Registradora",
	},
	{
		Imagem: Monitor,
		Texto: "Cadastro de clientes e fornecedores",
		Alt: "Monitor",
	},
	{ Imagem: Grafico, Texto: "DRE anual (mês a mês)", Alt: "Grafico" },
	{
		Imagem: Homem,
		Texto: "Usuários e conexões simultâneas no ERP",
		Alt: "Homem",
	},
	
	{ Imagem: Conta, Texto: "Múltiplas tabelas de preços", Alt: "Conta" },
];

export default conteudoWaychef;