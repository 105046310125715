import { textoSuporte } from "./TextosGerais";

export const TextosLumien = {
	0: textoSuporte,
	1: {
		titulo: "Como funciona o treinamento e implantação do sistema?",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"O treinamento e a implantação do Lumien são realizados presencialmente. Um técnico especializado acompanha sua equipe no local por até 4 dias, garantindo a fluidez e o bom funcionamento do sistema. Esse suporte inicial assegura que todos os funcionários estejam bem preparados para utilizar todas as funcionalidades do Lumien, proporcionando uma transição suave e eficiente."
			}
		}
	},
	2: {
		titulo: "Com o Lumien Pay, vou ter controle sobre quem está recebendo os pagamentos?",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"Sim, com o Lumien Pay você terá controle total sobre os pagamentos recebidos. O sistema permite a identificação e o controle de recebimentos por frentista, além de oferecer uma análise detalhada das movimentações de combustível por turno. Isso ajuda o proprietário a identificar e resolver possíveis gargalos de atendimento, otimizando o processo de vendas e garantindo maior eficiência operacional."
			}
		}
	},
	3: {
		titulo: "Ficarei sem sistema caso a internet falhe?",
		corpo: {
			0: {
				tipo: "p",
				texto: "Não, você não ficará sem sistema caso a internet falhe. O Lumien opera com um banco de dados offline, garantindo que todas as operações continuem funcionando normalmente, mesmo sem conexão com a internet. Isso proporciona maior segurança e confiabilidade para a gestão do seu posto de combustível."
			}
		}
	},
	4: {
		titulo:
			"Vou conseguir ter controle sobre a finalizadora?",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"Sim, o Lumien oferece controle abrangente sobre sua finalizadora. Você poderá gerenciar diversos dados importantes, como o controle de cartões e outras formas de pagamento. Essa funcionalidade permite uma administração mais precisa e eficiente das operações financeiras do seu posto, garantindo transparência e segurança nas transações."
			}
		}
	}
};
