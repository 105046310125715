import styled from "styled-components";
import Circulo from "../Circulo";

// Imagens líderes Sifat
import Bruno from "../../assets/imagens/lideresSifat/Bruno.webp";
import Edgar from "../../assets/imagens/lideresSifat/Edgar.webp";
import Edmar from "../../assets/imagens/lideresSifat/Edmar.webp";
import Leidiane from "../../assets/imagens/lideresSifat/Leidiane.webp";
import Marcos from "../../assets/imagens/lideresSifat/Marcos.webp";
import Poliana from "../../assets/imagens/lideresSifat/Poliana.webp";
import Renan from "../../assets/imagens/lideresSifat/Renan.webp";

// Imagens Líderes Prodati
import Adao from "../../assets/imagens/lideresProdati/Adao.webp";
import Bianca from "../../assets/imagens/lideresProdati/Bianca.webp";
import Diego from "../../assets/imagens/lideresProdati/Diego.webp";
import Felipe from "../../assets/imagens/lideresProdati/Felipe.webp";
import Rafael from "../../assets/imagens/lideresProdati/Rafael.webp";
import Vinicius from "../../assets/imagens/lideresProdati/Vinicius.webp";

// Imagens produtos Sifat
import Efex from "../../assets/imagens/produtosSifat/efex.webp";
import Flutt from "../../assets/imagens/produtosSifat/flutt.webp";
import Lumien from "../../assets/imagens/produtosSifat/lumien.webp";

// Imagens produtos Waybe
import waychef from "../../assets/imagens/produtosWaybe/waychef.webp";
import waypay from "../../assets/imagens/produtosWaybe/waypay.webp";
import wayservice from "../../assets/imagens/produtosWaybe/wayservice.webp";
import waymenu from "../../assets/imagens/produtosWaybe/waymenu.webp";

const Container = styled.div`
	display: flex;
	gap: 2em;
	flex-direction: column;
	max-width: 765px;
`;

const Titulo = styled.h1`
	color: white;
	font-weight: var(--strong-weight);
	font-size: var(--bigger-font);
	text-align: center;
`;

const CirculosContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
	gap: 2em;
`;

export function LideresProdati() {
	return (
		<Container>
			<Titulo>Nossos líderes</Titulo>
			<CirculosContainer>
				<Circulo
					imagem={Adao}
					temDetalhes={true}
					nome={"Adão Fernandes"}
					cargo={"Coordenador"}
					linkedinColor={"white"}
					url={`https://www.linkedin.com/in/adão-fernandes-09959a74/`}
				/>
				<Circulo
					imagem={Felipe}
					temDetalhes={true}
					nome={"Felipe Poletto"}
					cargo={"Líder Comercial"}
					linkedinColor={"white"}
					url={`https://www.linkedin.com/in/felipe-poletto-b111b7156/`}
				/>
				<Circulo
					imagem={Bianca}
					temDetalhes={true}
					nome={"Bianca Takara"}
					cargo={"Líder Suporte Rio Preto"}
					linkedinColor={"white"}
					url={`https://www.linkedin.com/in/bianca-takara-b19023109/`}
				/>
				<Circulo
					imagem={Rafael}
					temDetalhes={true}
					nome={"Rafael Pimentel"}
					cargo={"Líder Suporte São Paulo"}
					linkedinColor={"white"}
					url={`https://www.linkedin.com/in/rafael-oliveira-santos-pimentel-6b39231a0/`}
				/>
				<Circulo
					imagem={Vinicius}
					temDetalhes={true}
					nome={"Vinícius Martins"}
					cargo={"Líder Suporte Ribeirão Preto"}
					linkedinColor={"white"}
					url={`https://www.linkedin.com/in/vinicius-martins-358b33124/`}
				/>
				<Circulo
					imagem={Diego}
					temDetalhes={true}
					nome={"Diego Vellani"}
					cargo={"Supervisor"}
					linkedinColor={"white"}
					url={`https://www.linkedin.com/in/diego-vellani-a645951b7/`}
				/>
			</CirculosContainer>
		</Container>
	);
}

export function LideresSifat() {
	return (
		<Container>
			<Titulo>Nossos líderes</Titulo>
			<CirculosContainer>
				<Circulo
					imagem={Edmar}
					temDetalhes={true}
					nome={"Edmar de Paula"}
					cargo={"Diretor"}
					url={`https://www.linkedin.com/in/edmar-de-paula-b1b384144/`}
				/>
				<Circulo
					imagem={Marcos}
					temDetalhes={true}
					nome={"Marcos de Paula"}
					cargo={"Diretor"}
					url={`https://www.linkedin.com/in/marcos-de-paula-b4137a148/`}
				/>
				<Circulo
					imagem={Poliana}
					temDetalhes={true}
					nome={"Poliana Suzuki"}
					cargo={"Product Owner"}
					url={`https://www.linkedin.com/in/poliana-suzuki/`}
				/>
				<Circulo
					imagem={Bruno}
					temDetalhes={true}
					nome={"Bruno Storti"}
					cargo={"Product Owner"}
					url={`https://www.linkedin.com/in/bruno-storti-006320240/`}
				/>
				<Circulo
					imagem={Edgar}
					temDetalhes={true}
					nome={"Edgar Golin"}
					cargo={"Líder QA"}
					url={`https://www.linkedin.com/in/edgar-golin-78558020b/`}
				/>
				<Circulo
					imagem={Renan}
					temDetalhes={true}
					nome={"Renan Balthazar"}
					cargo={"Líder Técnico Dev"}
					url={`https://www.linkedin.com/in/renan-balthazar-32b874169/`}
				/>
				<Circulo
					imagem={Leidiane}
					temDetalhes={true}
					nome={"Leidiane Martins"}
					cargo={"Líder Administrativo"}
					url={`https://www.linkedin.com/in/leidiane-martins-paiva-585b0b225/`}
				/>
			</CirculosContainer>
		</Container>
	);
}

export function ProdutosSifat() {
	return (
		<Container>
			<Titulo>Nossos produtos</Titulo>
			<CirculosContainer>
				<Circulo imagem={Efex} temDetalhes={false} url={`/efex-pdv`}/>
				<Circulo imagem={Flutt} temDetalhes={false} url={`/sifat-flutt`}/>
				<Circulo imagem={Lumien} temDetalhes={false} url={`/sifat-lumien`}/>
			</CirculosContainer>
		</Container>
	);
}

export function ProdutosWaybe() {
	return (
		<Container>
			<Titulo>Nossos produtos</Titulo>
			<CirculosContainer>
				<Circulo imagem={waychef} temDetalhes={false} url={`/waychef`}/>
				<Circulo imagem={waymenu} temDetalhes={false} url={`/waymenu`}/>
				<Circulo imagem={waypay} temDetalhes={false} url={``}/>
				<Circulo imagem={wayservice} temDetalhes={false} url={``}/>
			</CirculosContainer>
		</Container>
	);
}
