import { textoSuporte } from "./TextosGerais";

export const TextosWaychef = {
	0: {
		titulo: "Quais tipos de lojas o Waychef atende?",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"O Waychef atende a uma ampla variedade de lojas no ramo do food				service, proporcionando soluções completas para gestão eficiente e sucesso nos negócios. Confira alguns exemplos:",
			},
			1: {
				tipo: "li",
				texto: [
					"Pizzaria",
					"Hamburguerias",
					"Restaurantes à la carte",
					"Bares e pubs",
					"Cafeterias",
					"Padarias e confeitarias",
					"Churrascarias",
					"Fast foods",
					"Restaurantes temáticos",
					"Food trucks",
					"Quiosques de praia",
					"Sorveterias",
				],
			},
			2: {
				tipo: "p",
				texto:
					"Não importa o tipo de estabelecimento que você possui, nosso sistema foi desenvolvido para atender às necessidades específicas do seu negócio e ajudá-lo a alcançar o sucesso.",
			},
		},
	},
	1: {
		titulo: "O sistema oferece funcionalidades de controle de estoque?",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"Sim, o sistema oferece funcionalidades abrangentes de controle de estoque, incluindo:",
			},
			1: {
				tipo: "li",
				texto: [
					"Inventário: Gerenciamento detalhado e atualizado dos itens em estoque.",
					"Lançamentos: Registro de todas as entradas e saídas de produtos no sistema.",
					"Local de estocagem: Possibilidade de definir e monitorar os locais específicos de armazenamento dos itens.",
					"Produção: Controle da produção interna de itens, permitindo o acompanhamento do processo de fabricação.",
					"Setores: Organização dos itens em categorias ou setores para facilitar o controle e a gestão.",
					"Ajustes: Capacidade de realizar ajustes de estoque, como correções de contagem ou danos.",
					"Transferências: Facilidade para transferir itens entre diferentes locais de armazenamento, como filiais ou depósitos.",
				],
			},
		},
	},
	2: {
		titulo: "O sistema é compatível com serviços de entrega?",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"Sim, o sistema é compatível com serviços de entrega, como o iFood e o aplicativo de cardápio digital WayMenu. Além disso, oferece recursos avançados para facilitar a operação de entrega. Você pode cadastrar dados dos clientes, como endereço, para agilizar o processo de entrega.",
			},
			1: {
				tipo: "p",
				texto:
					"Também é possível cadastrar entregadores e fazer o acompanhamento dos pedidos diretamente pelo PDV, garantindo uma gestão eficiente do serviço de entrega.",
			},
		},
	},
	3: {
		titulo: "Quais dispositivos são compatíveis com o sistema?",
		corpo: {
			0: {
				tipo: "p",
				texto: "O sistema é compatível com os seguintes dispositivos:",
			},
			1: {
				tipo: "li",
				texto: [
					"Equipamento Fiscal: DIMEP, DIMEP 2.0, DARUMA DS100i, EPSON, ELGIN, NITERE, CONTROL ID, BEMATECH, GERTEC, TANCA, SWEDA, KRYPTUS, CARE.",
					"Impressoras Térmicas: BEMATECH, DARUMA, ELGIN, TANCA, CONTROL ID, EPSON, SWEDA, DIEBOLD, JETWAY, GERTEC.",
					"Impressoras de etiqueta: ELGIN, ARGOX.",
					"Balança de Carga: TOLEDO PRIX III.",
					"Balanças de Checkout: TOLEDO, FILIZOLA, URANO.",
					"Smart POS: GPOS700 (GERTEC), ELGIN PAY, CIELO LIO, POSITIVO L400, INGÊNICO A8.", 
					"MINI PDV: M8 E M10 (ELGIN), IMIN,  TECTOY P2, TECTOY T2s, SK210 (GERTEC).",
					"Pinpad USB (Windows): GERTEC PPC900, PPC 910, PPC920 e PPC930, INGÊNICO, iCT2xx, 4.0, 111.3, iHT1, iHT2, iHT3, iHT4, iHT5, IPPxx, iSC2xx, iSC350, iST1xx, iUC1xx, iUP250, LANE3000, PAX D2000, VERIFONE Vx810, Vx820, VERIFONE P200.",
					"TEF: SITEF, PAYGO.",
					"Pinpad Bluetooth (Android): Gertec MobiPin, MP5.",
				],
			},
		},
	},
	4: {
		titulo:
			"Como é o processo de transição do meu sistema atual para o WayChef? É complicado?",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"O processo de transição para o WayChef é ágil e eficiente. Sua parametrização inicial é rápida, especialmente para atividades cotidianas como registro de vendas e consultas no ERP. A implantação é simplificada, com a maioria dos ajustes sendo feitos de forma rápida e direta.",
			},
			1: {
				tipo: "p",
				texto:
					"O sistema oferece uma interface intuitiva e amigável para o usuário, facilitando a adaptação. Além disso, o WayChef é uma solução moderna e inovadora, preparada para atender às demandas do mercado atual.",
			},
		},
	},
	5: {
		titulo: "Como é realizado o processo de impressão de pedidos?",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"Nosso sistema de impressão de pedidos é completíssimo, permitindo impressões de produção em múltiplos locais conforme necessário. Além disso, oferecemos vias do cliente e fiscal, fechamento de mesa, cancelamentos, transferências de mesas, suspensões e muito mais.",
			},
			1: {
				tipo: "p",
				texto:
					"Personalize suas vias de produção de acordo com suas preferências, utilizando padrões pré-definidos dentro do nosso sistema. Nosso gerenciador de impressão é configurado e executado junto ao sistema de PDV, sem a necessidade de aplicativos de terceiros, garantindo praticidade e eficiência.",
			},
		},
	},
	6: textoSuporte,
};
