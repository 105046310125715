import { textoSuporte } from "./TextosGerais";

export const TextosWaypay = {
	0: {
		titulo: "Vocês fornecem o equipamento de autoatendimento?",
		corpo: {
			0: {
				tipo: "p",
				texto: "Nós te ajudamos com isso! A Sifat produz exclusivamente o software, mas nós ajudamos na intermediação entre a sua loja e os nossos parceiros que produzem totens de autopagamento."
			}
		}
	},
	1: {...textoSuporte, titulo: "Está disponível suporte técnico ao adquirir o Waypay? "},
	2: {
		titulo: "Como funciona o pagamento de comandas no Waypay?",
		corpo: {
			0: {
				tipo: "p",
				texto: "O cliente precisa apenas apresentar o código da comanda ao leitor, o sistema exibirá o resumo do consumo, e o cliente poderá realizar o pagamento de forma autônoma e rápida, com a impressão do cupom fiscal logo em seguida."
			}
		}
	},
	3: {
		titulo: "Quais são as formas de pagamento aceitas?",
		corpo: {
			0: {
				tipo: "p",
				texto: "O Waypay aceita múltiplas formas de pagamento:"
			},
			1: {
				tipo: "li",
				texto: [
					"Cartão de Crédito",
					"Cartão de Débito",
					"Ticket/Vale alimentação",
					"Cashback",
					"Pix",
					"Dinheiro (necessário um atendente no caixa para confirmação de valores e troco)"
				]
			}
		}
	},
	4: {
		titulo: "Como funciona o Cashback?",
		corpo: {
			0: {
				tipo: "p",
				texto: "O cashback é um programa de recompensas que devolve ao consumidor uma porcentagem do valor gasto em uma compra. No nosso sistema ele funciona assim:"
			},
			1: {
				tipo: "li",
				texto: [
					"O gestor pode selecionar produtos participantes e definir a porcentagem de cashback creditada ao cliente.",
					"Para aproveitar o benefício, o cliente faz um cadastro simples no totem de autopagamento.",
					"O saldo de cashback pode ser consultado no link https://erp.waybe.com.br/plataforma-cashback, e o cliente pode utilizar o valor acumulado para abater parte do total do pedido ao pagar."
				]
			}
		}
	},
};
