import { textoSuporte } from "./TextosGerais";

export const TextosFlutt = {
	0: textoSuporte,
	1: {
		titulo: "Como funcionam os pedidos e orçamentos no sistema?",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"Tanto os pedidos quanto os orçamentos são montados da mesma forma. A diferença é que o orçamento não pode ser finalizado diretamente e não aloca produtos no estoque. Ou seja, o orçamento é criado para ser enviado ao cliente, gerar uma versão impressa e ser mantido salvo no sistema. Posteriormente, é possível transformar o orçamento em pedido, que então aloca os itens no estoque. Por exemplo, se há um item com 10 unidades em estoque e é feito um pedido de 5 unidades, ele será registrado como 'estoque 10, alocado 5, disponível 5'. O pedido pode ser finalizado diretamente no caixa, onde é importado e concluído, emitindo nota fiscal ou cupom fiscal."
			}
		}
	},
	2: {
		titulo: "O sistema trabalha com comissões de vendedores?",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"Sim, o sistema permite configurar comissões de vendedores de diversas formas. A comissão pode ser fixa para o vendedor, ou seja, um percentual sobre todas as vendas realizadas por ele. Também é possível configurar a comissão na tabela de preços, onde é aplicado um acréscimo específico sobre os preços de venda. Além disso, a comissão pode ser definida diretamente no cadastro do item, permitindo uma flexibilidade na forma como as comissões são calculadas.",
			}
		}
	},
	3: {
		titulo: "O sistema possui tabelas de preço?",
		corpo: {
			0: {
				tipo: "p",
				texto: "Sim, o sistema conta com tabelas de preço que permitem uma gestão eficiente dos preços de venda dos produtos. É possível cadastrar diferentes tabelas de preço e aplicar descontos ou acréscimos específicos a cada uma delas. Essa funcionalidade oferece uma maior flexibilidade na precificação dos produtos e na aplicação de políticas comerciais diferenciadas.",
			}
		}
	},
	4: {
		titulo:
			"Como funciona a pré-venda?",
		corpo: {
			0: {
				tipo: "p",
				texto:
					"A pré-venda é semelhante a um pedido, com a diferença de que é como uma venda suspensa que pode ser finalizada posteriormente. Geralmente, é realizada em um terminal específico para pré-vendas, onde os vendedores montam a pré-venda com os itens desejados pelos clientes. Após montada, a pré-venda é registrada no sistema e pode ser finalizada no caixa, onde é recuperada e concluída. Essa funcionalidade é útil em locais com vários vendedores e um único caixa, como grandes estabelecimentos ou oficinas."
			}
		},
	}
};
