// Icones das funcoes
import PedidoSemTaxa from "../../assets/imagens/waymenu-funcoes/PedidoSemTaxa.svg";
import PedidoMesa from "../../assets/imagens/waymenu-funcoes/PedidoMesa.svg";
import PedidosIlimitados from "../../assets/imagens/waymenu-funcoes/PedidosIlimitados.svg";
import PagamentoOn from "../../assets/imagens/waymenu-funcoes/PagamentoOn.svg";
import TaxaEntrega from "../../assets/imagens/waymenu-funcoes/TaxaEntrega.svg";
import Ifood from "../../assets/imagens/waymenu-funcoes/Ifood.svg";
import Cupom from "../../assets/imagens/waymenu-funcoes/Cupom.svg";
import Suporte from "../../assets/imagens/waymenu-funcoes/Suporte.svg";
import Layout from "../../assets/imagens/waymenu-funcoes/Layout.svg";
import Descricao from "../../assets/imagens/waymenu-funcoes/Descricao.svg";
import CardapioProgram from "../../assets/imagens/waymenu-funcoes/CardapioProgram.svg";
import Categoria from "../../assets/imagens/waymenu-funcoes/Categoria.svg";
import Cadastro from "../../assets/imagens/waymenu-funcoes/Cadastro.svg";
import DeliveryPdv from "../../assets/imagens/waymenu-funcoes/DeliveryPdv.svg";
import Plataforma from "../../assets/imagens/waymenu-funcoes/Plataforma.svg";
import DesignResp from "../../assets/imagens/waymenu-funcoes/DesignResp.svg";
import CardapioDigital from "../../assets/imagens/waymenu-funcoes/CardapioDigital.svg";
import PlanosAdap from "../../assets/imagens/waymenu-funcoes/PlanosAdap.svg";

const conteudoWaymenu = [
	{
		Imagem: PedidoSemTaxa,
		Texto: "Pedidos sem taxas*",
		Alt: "Símbolo de taxa",
	},
	{ Imagem: PedidoMesa, Texto: "Pedido na mesa com integração no PDV*", Alt: "Imagem de mesa" },
	{ Imagem: PedidosIlimitados, Texto: "Pedidos ilimitados*", Alt: "Símbolo do infinito" },
	{ Imagem: PagamentoOn, Texto: "Pagamento online*", Alt: "Imagem cartão de crédito" },
	{ Imagem: TaxaEntrega, Texto: "Taxa de entrega por raio e bairro*", Alt: "Símbolo de um mapa" },
	{
		Imagem: Ifood,
		Texto: "Integrado com iFood",
		Alt: "Logo ifood",
	},
	{ Imagem: Cupom, Texto: "Cupom de desconto com influencers*", Alt: "Símbolo ticket" },
	{ Imagem: Suporte, Texto: "Suporte via Chat", Alt: "Imagem atendente" },
	{
		Imagem: Layout,
		Texto: "Layout reduzido ou expandido",
		Alt: "Monitor e smartphone",
	},
	{
		Imagem: Descricao,
		Texto: "Descrição de produto com foto",
		Alt: "Imagem de fast food",
	},
	{ Imagem: CardapioProgram, Texto: "Cardápio programável por horário", Alt: "Imagem de sol e lua" },
	{ Imagem: Categoria, Texto: "Categoria de produtos com fotos", Alt: "Imagem página web" },
	{ Imagem: Cadastro, Texto: "Cadastro de produtos ilimitado", Alt: "Ícone carrinho de compra" },
	{ Imagem: DeliveryPdv, Texto: "Delivery com integração no PDV", Alt: "Imagem toldo de loja" },
	{
		Imagem: Plataforma,
		Texto: "Plataforma gerencial completa",
		Alt: "Imagem de gráfico",
	},
	{
		Imagem: DesignResp,
		Texto: "Design responsivo",
		Alt: "Imagem monitor e smartphone",
	},
	{ Imagem: CardapioDigital, Texto: "Cardápio digital por QR Code", Alt: "Logo Waymenu" },
	{ Imagem: PlanosAdap, Texto: "Planos adaptáveis ao seu negócio", Alt: "Imagem quebra-cabeça" },
];

export default conteudoWaymenu;