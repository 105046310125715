import styled from "styled-components";
import Logo from "../../assets/imagens/LogoSifatAzul.svg";
import { useCallback, useEffect, useRef, useState, forwardRef } from 'react';

const easeOutExpo = (t) => {
	return t === 1 ? 1 : 1 - Math.pow(2, -10 * t)
}

const useScrollTriggeredCountUp = (ref, end, duration) => {
	const [count, setCount] = useState(0)
	const isCounting = useRef(false)
	const frameRate = 1000 / 60
	const totalFrames = Math.round(duration / frameRate)

	const handleScroll = useCallback(
		([entry]) => {
			if (entry.isIntersecting && !isCounting.current) {
				isCounting.current = true
				let frame = 0

				const counter = setInterval(() => {
					frame++
					const progress = easeOutExpo(frame / totalFrames)
					setCount(Math.round(end * progress))

					if (frame === totalFrames) {
						clearInterval(counter)
						isCounting.current = false
					}
				}, frameRate)
			} else {
				isCounting.current = false
				setCount(0)
			}
		},
		[end, frameRate, totalFrames]
	)

	useEffect(() => {
		const observer = new IntersectionObserver(handleScroll, { threshold: 0.7 })
		const currentRef = ref.current

		if (currentRef) {
			observer.observe(currentRef)
		}

		return () => {
			if (currentRef) {
				observer.unobserve(currentRef)
			}
		}
	}, [handleScroll, ref])

	return count
}

const Container = styled.div`
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1.2em;
	border-radius: var(--border-radius-m);
	padding: 1em;

	@media screen and (min-width: 550px) {
		padding: 3em;
	}

	@media screen and (min-width: 993px) {
		flex-direction: row;
		align-items: unset;
		gap: 2rem;
	}
`;

const ContainerLogo = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: ${(props) => props.$gap || "1.2em"};

	@media screen and (min-width: 993px) {
		width: 30%;
	}
`;

const ContainerNumeros = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: ${(props) => props.$gap || "1.2em"};

	@media screen and (min-width: 993px) {
		width: 70%;
		align-items: unset;
		justify-content: space-between;
		gap: 0;
	}
`;

const NumeroGrandeContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	@media screen and (min-width: 993px) {
		flex-direction: row;
		gap: 1rem;
	}
`;

const StyledNumeroGrande = styled.span`
    color: #1b5ea4;
    font-weight: var(--strong-weight);
    font-size: 4em;
`;

const NumeroGrande = forwardRef((props, ref) => {
    return <StyledNumeroGrande ref={ref} {...props} />;
});

const NumeroGrandeWrapper = styled.div`
	display: flex;
	flex: 1;
	justify-content: flex-end;
`;

const Titulo = styled.h1`
	font-weight: var(--strong-weight);
	font-size: var(--bigger-font);
	text-align: center;

	@media screen and (min-width: 993px) {
		text-align: left;
	}
`;

const LogoSifat = styled.img`
	width: 7em;
	height: auto;

	@media screen and (min-width: 993px) {
		width: auto;
	}
`;

const Paragrafo = styled.p`
	text-align: center;
	margin: 0;
	font-weight: var(--regular-weight);
	font-size: var(--medium-font);

	@media screen and (min-width: 993px) {
		text-align: left;
	}
`;

const ParagrafoWrapper = styled.div`
	display: flex;
	flex: 1;

	@media screen and (max-width: 993px) {
		margin-top: -0.8em;
	}
`;

export default function NumerosDaSifatTop() {
	const ref1 = useRef(null);
	const ref2 = useRef(null);
	const ref3 = useRef(null);
	const ref4 = useRef(null);
	const count1 = useScrollTriggeredCountUp(ref1, 30, 4000);
	const count2 = useScrollTriggeredCountUp(ref2, 23000, 3000);
	const count3 = useScrollTriggeredCountUp(ref3, 6000, 3000);
	const count4 = useScrollTriggeredCountUp(ref4, 26, 4000);

	return (
		<Container>
			<ContainerLogo>
				<Titulo>
					Excelência em{" "}
					<span style={{ color: "#1B5EA4" }}>sistemas de gestão.</span>
				</Titulo>
				<LogoSifat src={Logo} alt={"Logo Sifat"} />
			</ContainerLogo>
			<ContainerNumeros $gap={"2em"}>
				<Paragrafo>
					Encontre a solução certa para a gestão do seu negócio. Invista no seu
					sistema ERP com quem tem anos de experiência e estabilidade no
					mercado!
				</Paragrafo>
				<NumeroGrandeContainer>
					<NumeroGrandeWrapper>
						<NumeroGrande ref={ref1}>{count1}</NumeroGrande>
					</NumeroGrandeWrapper>
					<ParagrafoWrapper>
						<Paragrafo>ANOS DE EXPERIÊNCIA COM SISTEMA DE GESTÃO</Paragrafo>
					</ParagrafoWrapper>
				</NumeroGrandeContainer>
				<NumeroGrandeContainer>
					<NumeroGrandeWrapper>
						<NumeroGrande ref={ref2}>{count2}</NumeroGrande>
					</NumeroGrandeWrapper>
					<ParagrafoWrapper>
						<Paragrafo>
							ESTAÇÕES DE TRABALHO FUNCIONANDO A TODO O VAPOR
						</Paragrafo>
					</ParagrafoWrapper>
				</NumeroGrandeContainer>
				<NumeroGrandeContainer>
					<NumeroGrandeWrapper>
						<NumeroGrande ref={ref3}>{count3}</NumeroGrande>
					</NumeroGrandeWrapper>
					<ParagrafoWrapper>
						<Paragrafo>CLIENTES SATISFEITOS</Paragrafo>
					</ParagrafoWrapper>
				</NumeroGrandeContainer>
				<NumeroGrandeContainer>
					<NumeroGrandeWrapper>
						<NumeroGrande ref={ref4}>{count4}</NumeroGrande>
					</NumeroGrandeWrapper>
					<ParagrafoWrapper>
						<Paragrafo>ESTADOS ATENDIDOS + DISTRITO FEDERAL</Paragrafo>
					</ParagrafoWrapper>
				</NumeroGrandeContainer>
			</ContainerNumeros>
		</Container>
	);
}
