import styled from "styled-components";
import { Link } from "react-router-dom";
//Imagens
import LogoSifatAzulHorizontal from "../../assets/imagens/Logomarca Sifat 2019 - Azul Horizontal.png";
import FundoCinzaOnda from "../../assets/imagens/FundoCinzaOnda.webp";

const StyledLogoSifatAzulHorizontal = styled.img`
	width: 11em;
	height: auto;
`;

const FooterContainer = styled.div`
	background-image: url(${FundoCinzaOnda});
	background-size: cover;
	padding-top: 9em;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const FooterLimitador = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: var(--max-section-w);
`;

const DivLogoESociais = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.2em;
	align-items: center;
`;

const UlSocialLinks = styled.ul`
	list-style: none;
	padding: 0;
	color: #1b5ea4;
	font-size: 2em;
	display: flex;
	gap: 1.5em;
	align-items: center;
	justify-content: center;
	flex-direction: row;
`;

const DivLinksWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.2em;
	text-align: center;

	@media screen and (min-width: 650px) {
		text-align: center;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
		padding: 2em;
	}

	@media screen and (min-width: 800px) {
		text-align: unset;
		align-items: unset;
	}
`;

const DivLinks = styled.div`
	color: #1b355e;
	display: flex;
	flex-direction: column;
	font-size: var(--small-font);
	padding: 0.6em;
	gap: 0.7em;

	@media screen and (min-width: 650px) {
		text-align: left;
		width: 45%;
	}

	@media screen and (min-width: 800px) {
		width: auto;
	}
`;

const Titulo = styled.h1`
	font-size: var(--medium-font);
	font-weight: var(--strong-weight);
`;

const LinkStyled = styled(Link)`
	text-decoration: none;
	color: #1b355e;
	font-weight: var(--weak-weight);
	font-size: var(--small-font);

	&:hover {
		color: #1b355e;
	}

	&:link {
		color: #1b355e;
	}

	&:visited {
		color: #1b355e;
	}
`;

const Texto = styled.span`
	color: #1b355e;
	font-weight: var(--weak-weight);
`;

const LinksContatosWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0.6em;
	gap: 0.7em;
`;

const Powered = styled.span`
	padding: 2em;
	color: #1b355e;
	font-size: var(--small-font);
	font-weight: var(--strong-weight);
`;

export default function Footer() {
	return (
		<FooterContainer>
			<FooterLimitador>
				<DivLogoESociais>
					<StyledLogoSifatAzulHorizontal
						src={LogoSifatAzulHorizontal}
						alt={"Logo Sifat"}
					/>

					<UlSocialLinks>
						<li>
							<LinkStyled to={`https://www.linkedin.com/company/grupo-sifat-sistemas/`}>
								<i className="bi bi-linkedin"></i>
							</LinkStyled>
						</li>
						<li>
							<LinkStyled to={`https://www.instagram.com/sifatsistemas/`}>
								<i className="bi bi-instagram"></i>
							</LinkStyled>
						</li>
						<li>
							<LinkStyled to={`https://www.facebook.com/sifatsistemas`}>
								<i className="bi bi-facebook"></i>
							</LinkStyled>
						</li>
						<li>
							<LinkStyled to={`https://www.youtube.com/@sifatsistemas4362`}>
								<i className="bi bi-youtube"></i>
							</LinkStyled>
						</li>
					</UlSocialLinks>
				</DivLogoESociais>

				<DivLinksWrapper>
					<DivLinks>
						<Titulo>Sifat Sistemas</Titulo>
						<LinkStyled to="/">Home</LinkStyled>
						<LinkStyled to="/quem-somos-sifat">Sobre a Sifat</LinkStyled>
						<LinkStyled to={`/quem-somos-prodati`}>Sobre a Prodati</LinkStyled>
						<LinkStyled to={`/quem-somos`}>Sobre a Waybe</LinkStyled>
						<LinkStyled to={`/fale-conosco`}>Fale conosco</LinkStyled>
						<LinkStyled to={`/integrador`}>Seja um parceiro</LinkStyled>
						<LinkStyled to={`/trabalhe-conosco`}>Trabalhe conosco</LinkStyled>
						<LinkStyled to={`https://www.teamviewer.com/pt-br/download/windows/`}>Team Viewer</LinkStyled>
						<LinkStyled to={`http://portal.sifat.com.br/login`}>Portal</LinkStyled>
						<LinkStyled to={`http://cliente.sifat.com.br/login`}>Área do cliente</LinkStyled>
					</DivLinks>

					<DivLinks>
						<Titulo>Soluções</Titulo>
						<LinkStyled to={`/waychef`}>
							Waychef - ERP + PDV Food Service
						</LinkStyled>
						<LinkStyled to={`/waymenu`}>
							Waymenu - Delivery e Cardápio Digital
						</LinkStyled>
						<LinkStyled to={`/waypay`}>
							Waypay - Autopagamento
						</LinkStyled>
						<LinkStyled to={`/wayservice`}>
							Wayservice - Autoatendimento
						</LinkStyled>
						<LinkStyled to={`/efex-pdv`}>
							Efex - PDV Mercados e casa de carnes
						</LinkStyled>
						<LinkStyled to={`/sifat-lumien`}>Lumien - Posto de combustível</LinkStyled>
						<LinkStyled to={`/sifat-flutt`}>Flutt - PDV Varejo</LinkStyled>
					</DivLinks>

					<DivLinks>
						<Titulo>Termos e condições</Titulo>
						<LinkStyled to={`/politica-de-privacidade`}>Política de privacidade</LinkStyled>
					</DivLinks>

					<DivLinks>
						<Titulo>Fale conosco</Titulo>
						<Texto>Rua São Carlos, 272, Jardim Europa</Texto>
						<Texto>São José do Rio Preto – SP</Texto>
						<LinksContatosWrapper>
							<LinkStyled to={`https://api.whatsapp.com/send?l=pt_br&phone=5517996349340`}>
								<i className="bi bi-whatsapp"></i> 17 99634-9340
							</LinkStyled>
							<LinkStyled to={`tel:+551731228600`}>
								<i className="bi bi-telephone-fill"></i> 17 3122-8600
							</LinkStyled>
							<LinkStyled to={`tel:+551628328600`}>
								<i className="bi bi-telephone-fill"></i> 16 2832-8600
							</LinkStyled>
							<LinkStyled to={`tel:+551134568686`}>
								<i className="bi bi-telephone-fill"></i> 11 3456-8686
							</LinkStyled>
							<LinkStyled to={`mailto:contato@sifat.com.br`}>
								<i className="bi bi-envelope"></i> contato@sifat.com.br
							</LinkStyled>
						</LinksContatosWrapper>
					</DivLinks>
				</DivLinksWrapper>
				<Powered>Powered by Sifat</Powered>
			</FooterLimitador>
		</FooterContainer>
	);
}
