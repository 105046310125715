// Icones das funcoes
import CadastroProdutos from "../../assets/imagens/flutt-funcoes/cadastro de produtos.svg";
import CadastroClientes from "../../assets/imagens/flutt-funcoes/cadastro de clientes.svg";
import CadastroPromo from "../../assets/imagens/flutt-funcoes/cadastro de promocoes.svg";
import ControleTrocas from "../../assets/imagens/flutt-funcoes/Controle de trocas.svg";
import ControleCondicionais from "../../assets/imagens/flutt-funcoes/Controle de condicionais.svg";
import ControleVale from "../../assets/imagens/flutt-funcoes/Controle de vale presente.svg";
import ControleCaixa from "../../assets/imagens/flutt-funcoes/controle de caixa.svg";
import AdmFinanceira from "../../assets/imagens/flutt-funcoes/administracao financeira.svg";
import Vendas from "../../assets/imagens/flutt-funcoes/Vendas rápidas.svg";
import NFE from "../../assets/imagens/flutt-funcoes/emissao fiscal de nfe e nfse.svg";
import CFE from "../../assets/imagens/flutt-funcoes/Emissão fiscal de cf-e sat e nfce.svg";
import ControleCartoes from "../../assets/imagens/flutt-funcoes/controle de cartoes.svg";
import TEF from "../../assets/imagens/flutt-funcoes/Integração TEF.svg";
import Dashboard from "../../assets/imagens/flutt-funcoes/dashboard completa.svg";
import Relatorios from "../../assets/imagens/flutt-funcoes/relatorios gerenciais completos.svg";
import ABC from "../../assets/imagens/flutt-funcoes/Curva ABC.svg";
import MixProdutos from "../../assets/imagens/flutt-funcoes/Gerenciamento do mix de produtos.svg";
import App from "../../assets/imagens/flutt-funcoes/aplicativo de vendas no celular.svg";

const conteudoFlutt = [
	{
		Imagem: CadastroProdutos,
		Texto: "Cadastro de produtos",
		Alt: "Imagem de caixa",
	},
	{ Imagem: CadastroClientes, Texto: "Cadastro de clientes", Alt: "Imagem de pessoa" },
	{ Imagem: CadastroPromo, Texto: "Cadastro de promoções", Alt: "Imagem de carrinho de compra" },
	{ Imagem: ControleTrocas, Texto: "Controle de trocas", Alt: "Símbolo de troca" },
	{ Imagem: ControleCondicionais, Texto: "Controle de condicionais", Alt: "Símbolo de condicionais" },
	{
		Imagem: ControleVale,
		Texto: "Controle de vale presente",
		Alt: "Imagem de ticket",
	},
	{ Imagem: ControleCaixa, Texto: "Controle de caixa", Alt: "Imagem de caixa-registradora" },
	{ Imagem: AdmFinanceira, Texto: "Administração financeira", Alt: "Imagem de papel" },
	{
		Imagem: Vendas,
		Texto: "Vendas rápidas",
		Alt: "Imagem de cronómetro",
	},
	{
		Imagem: NFE,
		Texto: "Emissao fiscal de NFE e NFSE",
		Alt: "Imagem de recibo de pagamento",
	},
	{ Imagem: CFE, Texto: "Emissao fiscal de CF-E SAT e NFCE", Alt: "Imagem de planilha" },
	{ Imagem: ControleCartoes, Texto: "Controle cartões", Alt: "Imagem de cartões de crédito" },
	{ Imagem: TEF, Texto: "Integração TEF", Alt: "Imagem de maquina de cartão" },
	{ Imagem: Dashboard, Texto: "Dashboard completa", Alt: "Imagem de gráficos" },
	{
		Imagem: Relatorios,
		Texto: "Relatórios gerenciais completos",
		Alt: "Imagem de gráfico",
	},
	{
		Imagem: ABC,
		Texto: "Curva ABC",
		Alt: "Símbolo de porcentagem",
	},
	{ Imagem: MixProdutos, Texto: "Gerenciamento do mix de produtos", Alt: "Imagem de caixa" },
	{ Imagem: App, Texto: "Aplicativo de vendas no celular", Alt: "Imagem de celular" },
];

export default conteudoFlutt;