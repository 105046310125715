// Icones das funcoes
import Cadastro from "../../assets/imagens/efex-funcoes/cadastro de produtos.svg";
import Concentrador from "../../assets/imagens/efex-funcoes/concentrador fiscal.svg";
import ControleEstoque from "../../assets/imagens/efex-funcoes/controle de estoque.svg";
import ControleCartoes from "../../assets/imagens/efex-funcoes/controle de cartoes.svg";
import IntegradoBalanca from "../../assets/imagens/efex-funcoes/integrado com balança de pesagem.svg";
import SistemaPagamento from "../../assets/imagens/efex-funcoes/sistema de autopagamento.svg";
import Promocoes from "../../assets/imagens/efex-funcoes/cadastro de promocoes.svg";
import SPED from "../../assets/imagens/efex-funcoes/gerador de sped.svg";
import Administracao from "../../assets/imagens/efex-funcoes/administracao financeira.svg";
import CRM from "../../assets/imagens/efex-funcoes/integrado com crm.svg";
import Dashboard from "../../assets/imagens/efex-funcoes/dashboard gerencial.svg";
import Offline from "../../assets/imagens/efex-funcoes/operacional mesmo sem internet.svg";
import CadastroClientes from "../../assets/imagens/efex-funcoes/cadastro de clientes.svg";
import ColetorDados from "../../assets/imagens/efex-funcoes/coletor de dados.svg";
import TEF from "../../assets/imagens/efex-funcoes/homologado com auttar e sitef tef.svg";
import Cotacao from "../../assets/imagens/efex-funcoes/plataforma de cotação online.svg";
import Relatorios from "../../assets/imagens/efex-funcoes/relatorios completos.svg";
import SiteMercado from "../../assets/imagens/efex-funcoes/integrado com site mercado.svg";

const conteudoEfex = [
	{
		Imagem: Cadastro,
		Texto: "Cadastro de produtos",
		Alt: "Carrinho de compra",
	},
	{ Imagem: Concentrador, Texto: "Concentrador fiscal", Alt: "Imagem de planilha" },
	{ Imagem: ControleEstoque, Texto: "Controle de estoque", Alt: "Imagem de caixa" },
	{ Imagem: ControleCartoes, Texto: "Controle de cartões", Alt: "Imagem cartão de crédito" },
	{ Imagem: IntegradoBalanca, Texto: "Integrado com balança de pesagem", Alt: "Imagem de balança" },
	{
		Imagem: SistemaPagamento,
		Texto: "Sistema de autopagamento",
		Alt: "Logo ifood",
	},
	{ Imagem: Promocoes, Texto: "Cadastro de promoções", Alt: "Imagem de totem de autoatendimento" },
	{ Imagem: SPED, Texto: "Gerador de SPED", Alt: "Imagem de relatório" },
	{
		Imagem: Administracao,
		Texto: "Administração financeira",
		Alt: "Imagem de caixa-registradora",
	},
	{
		Imagem: CRM,
		Texto: "Integrado com CRM",
		Alt: "Imagem de imã",
	},
	{ Imagem: Dashboard, Texto: "Dashboard gerencial", Alt: "Imagem de gráfico" },
	{ Imagem: Offline, Texto: "Operacional mesmo sem internet", Alt: "Ícone de conexão inativa" },
	{ Imagem: CadastroClientes, Texto: "Cadastro de clientes", Alt: "Imagem de pessoa" },
	{ Imagem: ColetorDados, Texto: "Coletor de dados", Alt: "Imagem de mão segurando caixa" },
	{
		Imagem: TEF,
		Texto: "Homologado com Auttar e Sitef (TEF)",
		Alt: "Imagem de máquina de pagamento",
	},
	{
		Imagem: Cotacao,
		Texto: "Plataforma de cotação online",
		Alt: "Símbolo de porcentagem",
	},
	{ Imagem: Relatorios, Texto: "Relatórios completos", Alt: "Imagem de gráfico" },
	{ Imagem: SiteMercado, Texto: "Integrado com Site Mercado", Alt: "Logo do SiteMercado" },
];

export default conteudoEfex;