import { textoSuporte } from "./TextosGerais";

export const TextosWayservice = {
	0: {
		titulo: "Vocês fornecem o equipamento de autoatendimento?",
		corpo: {
			0: {
				tipo: "p",
				texto: "Nós te ajudamos com isso! A Sifat produz exclusivamente o software, mas nós ajudamos na intermediação entre a sua loja e os nossos parceiros que produzem totens de autoatendimento para a sua empresa."
			}
		}
	},
	1: {...textoSuporte, titulo: "Está disponível suporte técnico ao adquirir o Wayservice?"},
	2: {
		titulo: "Como funciona a impressão de comandas?",
		corpo: {
			0: {
				tipo: "p",
				texto: "Nós trabalhamos para deixar a impressão das comandas o mais flexível possível. No Wayservice, a impressão de comandas pode ser alfanumérico, numérico sequencial ou numérico não sequencial. E é claro que, junto com a comanda, o totem também faz a impressão do cupom fiscal com a opção de CPF na nota."
			}
		}
	},
	3: {
		titulo: "Quais são as formas de pagamento aceitas?",
		corpo: {
			0: {
				tipo: "p",
				texto: "O Wayservice tem suporte para 6 formas de pagamento diferentes:"
			},
			1: {
				tipo: "li",
				texto: [
					"Cartão de Crédito",
					"Cartão de Débito", 
					"Ticket/Vale alimentação", 
					"Cashback", 
					"Pix", 
					"Dinheiro (necessário um atendente no caixa para confirmação de valores e troco)"
				]
			}
		}
	},
	4: {
		titulo: "Como funciona o Cashback?",
		corpo: {
			0: {
				tipo: "p",
				texto: "O cashback é um programa de recompensas que devolve ao consumidor uma porcentagem do valor gasto em uma compra. No nosso sistema ele funciona deste modo:"
			},
			1: {
				tipo: "li",
				texto: [
					"O gestor do restaurante pode selecionar alguns produtos para participarem desse programa e definir a porcentagem de cashback que será creditada ao cliente.",
					"Para aproveitar o benefício, o cliente precisa fazer um cadastro simples no próprio totem de autoatendimento.",
					"Além disso, ele pode consultar o saldo disponível na loja acessando o link https://erp.waybe.com.br/plataforma-cashback.",
					"Na hora de efetuar o pagamento, o cliente terá a opção de utilizar o valor acumulado em cashback para abater parte do total do pedido."
				]
			}
		}
	},
};
