// Icones das funcoes
import IntegradoAutomacao from "../../assets/imagens/lumien-funcoes/integrado com automacao de bombas.svg";
import CadastroClientes from "../../assets/imagens/lumien-funcoes/cadastro de clientes.svg";
import ControleFrota from "../../assets/imagens/lumien-funcoes/controle de frota de veiculos.svg";
import Combustivel from "../../assets/imagens/lumien-funcoes/movimentacoes de combustiveis por turno.svg";
import Lmc from "../../assets/imagens/lumien-funcoes/controle de l.m.c.svg";
import Caixa from "../../assets/imagens/lumien-funcoes/gestao de caixa.svg";
import SPED from "../../assets/imagens/lumien-funcoes/emissao fiscal e geracao de sped.svg";
import GestaoEquipe from "../../assets/imagens/lumien-funcoes/gestao de equipe.svg";
import Produtividade from "../../assets/imagens/lumien-funcoes/gestao de produtividade.svg";
import EntradaSaida from "../../assets/imagens/lumien-funcoes/controle de entradas e saidas da conveniencia.svg";
import PrecoGrupo from "../../assets/imagens/lumien-funcoes/preco por grupo de clientes.svg";
import Nivelamento from "../../assets/imagens/lumien-funcoes/nivelamento de preco.svg";
import GestaoMobile from "../../assets/imagens/lumien-funcoes/aplicativo de gestao mobile.svg";
import PDV from "../../assets/imagens/lumien-funcoes/pdv na maquininha de cartao.svg";
import Relatorios from "../../assets/imagens/lumien-funcoes/relatorios gerenciais completos.svg";
import ControleFatura from "../../assets/imagens/lumien-funcoes/controle de fatura por cliente.svg";
import Estoque from "../../assets/imagens/lumien-funcoes/cadastro e controle de estoque.svg";
import Autopagamento from "../../assets/imagens/lumien-funcoes/autopagamento de comandas.svg";

const conteudoLumien = [
	{
		Imagem: IntegradoAutomacao,
		Texto: "Integrado com automação de bombas",
		Alt: "Imagem de posto",
	},
	{ Imagem: CadastroClientes, Texto: "Cadastro de clientes", Alt: "Imagem de pessoa" },
	{ Imagem: ControleFrota, Texto: "Controle de frota de veículos", Alt: "Imagem de carros" },
	{ Imagem: Combustivel, Texto: "Movimentação de combustíveis por turno", Alt: "Ícone de combustível" },
	{ Imagem: Lmc, Texto: "Controle de L.M.C.", Alt: "Imagem de caderno" },
	{
		Imagem: Caixa,
		Texto: "Gestão de caixa",
		Alt: "Imagem caixa-registradora",
	},
	{ Imagem: SPED, Texto: "Emissão fiscal e geração de SPED", Alt: "Imagem de cupom fiscal" },
	{ Imagem: GestaoEquipe, Texto: "Gestão de equipe", Alt: "Imagem de equipe" },
	{
		Imagem: Produtividade,
		Texto: "Gestão de produtividade",
		Alt: "Imagem de relógio",
	},
	{
		Imagem: EntradaSaida,
		Texto: "Controle de entradas e saídas da conveniência",
		Alt: "Imagem de carrinho de compra",
	},
	{ Imagem: PrecoGrupo, Texto: "Preço por grupo de clientes", Alt: "Imagem de imã" },
	{ Imagem: Nivelamento, Texto: "Nivelamento de preço", Alt: "Ícone de dinheiro" },
	{ Imagem: GestaoMobile, Texto: "Aplicativo de gestão mobile", Alt: "Imagem de aplicativo" },
	{ Imagem: PDV, Texto: "PDV na maquininha de cartão", Alt: "Imagem maquininha de cartão" },
	{
		Imagem: Relatorios,
		Texto: "Relatórios gerenciais completos",
		Alt: "Imagem de gráficos",
	},
	{
		Imagem: ControleFatura,
		Texto: "Controle de fatura por cliente",
		Alt: "Imagem de planilha",
	},
	{ Imagem: Estoque, Texto: "Cadastro e controle de estoque", Alt: "Imagem de caixa" },
	{ Imagem: Autopagamento, Texto: "Autopagamento de comandas", Alt: "Imagem de totem de pagamento" },
];

export default conteudoLumien;