import BannerNavegacao from "../../components/BannerNavegacao";
import { BalaoSobreProdati } from "../../components/BaloesTexto";
import SecaoPagina from "../../components/SecaoPagina";
import { LideresProdati } from "../../components/GrupoCirculos";
import PageLayout from "../../layouts/PageLayout";
import { ContatoEHorarios } from "../../components/HorariosAtendimento";
// Imagens
import FundoVerdeSemTelefone from "../../assets/imagens/FundoVerdeSemTelefone.png";
import FundoVerdeOnda from "../../assets/imagens/FundoVerdeOnda.webp";

export default function PaginaSobreProdati() {
	return (
		<PageLayout>
			<BannerNavegacao pagina={"Prodati"} />

			<SecaoPagina
				$gap={"2em"}
				$bgimagem={FundoVerdeSemTelefone}
				$paddingmin={"2em 1em"}
				$paddingmax={"4em 4em 0"}
				$position={"left"}
			>
				<BalaoSobreProdati />
			</SecaoPagina>

			<SecaoPagina $paddingmin={"2em"} $bgimagem={null} $bgcolor={"#f4f4f4"}>
				<ContatoEHorarios />
			</SecaoPagina>

			<SecaoPagina
				$bgimagem={FundoVerdeOnda}
				$paddingmin={"2em 2em 9em"}
				$paddingmid={"2em 2em 9em"}
				$paddingmax={"4em 4em 8em"}
				$position={"center"}
				$gap={"3em"}
			>
				<LideresProdati />
			</SecaoPagina>
		</PageLayout>
	);
}
