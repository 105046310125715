import styled from "styled-components";
import { BotaoEnviar } from "../Botoes";
import Blog from "../../assets/imagens/Blog.png";
import { Link } from "react-router-dom";

const FormContainer = styled.div`
	background-color: #1b355e;
	padding: 1em;
	width: 100%;
	margin-top: -8em;
	margin-bottom: -7em;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: var(--smaller-font);
	filter: drop-shadow(1px 1px 4px black);
	
	@media screen and (min-width: 750px) {
		height: 15em;
		border-radius: var(--border-radius-l);
		height: 8em;
		width: fit-content;
		border-radius: var(--border-radius-l);
		margin-top: -4em;
		margin-bottom: -4em;
		width: fit-content;
		padding: 0 2em;
	}
`;

const FormWrapperOuter = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 0.4em;
`;

const FormWrapperInner = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.5em;
	flex-wrap: wrap;

	@media screen and (min-width: 300px) {
		flex-wrap: nowrap;
	}
`;

const Imagem = styled.img`
	display: none;

	@media screen and (min-width: 700px) {
		display: block;
		width: 11rem;
		padding: 0 2rem;
	}
`;

const Aviso = styled.small`
	margin: 0 0 0 0.7em;
	color: white;
	display: block;
	font-size: var(--smallest-font);
`;

const LinkStyled = styled(Link)`
	font-weight: var(--regular-weight);
`;

const InputLabel = styled.label`
	padding: 0 0 0.2em 0.7em;
	color: white;
`;

const Campo = styled.input`
	width: 100%;
	border: none;
	border-radius: var(--border-radius-l);
	padding: 0.4em;

	&::placeholder {
		padding: 0 1em;
	}

	@media screen and (min-width: 300px) {
		width: 55vw;
		max-width: 600px;
	}
`;

const Form = styled.form``;

export default function FormReceberAtualizacoes() {
	return (
		<>
			<FormContainer>
				<FormWrapperOuter>
					<Form>
						<InputLabel htmlFor="emailParaAtualizacao">
							Receba atualizações da Sifat no seu e-mail!
						</InputLabel>
						<FormWrapperInner>
							<Campo
								type="text"
								name="emailParaAtualizacao"
								id="emailParaAtualizacao"
								placeholder="e-mail@dominio.com.br"
							/>
							<BotaoEnviar />
						</FormWrapperInner>
					</Form>
					<Aviso>
						Ao se cadastrar, você irá concordar com nossa{" "}
						<LinkStyled to={`/politica-de-privacidade`}>política de privacidade</LinkStyled>
					</Aviso>
				</FormWrapperOuter>
				<Imagem src={Blog} />
			</FormContainer>
		</>
	);
}
