import styled from "styled-components";
import {CaixaDupla, CaixaUnitaria } from "../CaixaPreco";
import { HorariosAtendimento } from "../../components/HorariosAtendimento";

const CaixaPrecoContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	gap: 1em;

	@media screen and (min-width: 650px) {
		justify-content: center;
		flex-direction: row;
	}
`;

const Titulo = styled.h1`
	color: white;
	font-weight: var(--strong-weight);
	font-size: var(--bigger-font);
	text-align: center;
`;

const Subtitulo = styled.h2`
	color: white;
	font-weight: var(--regular-weight);
	font-size: var(--medium-font);
	text-align: center;
`;

const TituloContainer = styled.div`
    display: flex;
	flex-direction: column;
`;

export default function CaixasPrecosWaychef({ titulo, valor }) {
	return (
		<CaixaPrecoContainer>
            <TituloContainer>
                <Titulo>Serviços adicionais</Titulo >
                <Subtitulo>Confira os serviços adicionais que a Sifat oferece para o seu Waychef PDV.</Subtitulo>
            </TituloContainer>

            <CaixaUnitaria
                titulo={"Conexões Simultâneas Extras"}
                valor={"R$ 13,00"}
            />
            
            <CaixaUnitaria
                titulo={"Módulo Fiscal (CF-e/NFC-e/SAT)"}
                valor={"R$ 54,00"}
            />

            <CaixaDupla 
                titulo1={"Suporte Premium"}
                valor1={"R$ 40,00"}
                titulo2={"Suporte Premium + Plantão"}
                valor2={"R$ 90,00"}
            />

            <HorariosAtendimento color={"white"}/>
        </CaixaPrecoContainer>
	);
}