import styled from "styled-components";
import LogoWaychef from "../../assets/imagens/logos-produtos/LogoWaychefBrancoTransparente.png";
import { BotaoTesteGratisWaychefPequeno, BotaoUseGratisWaychefPequeno } from "../Botoes";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 0.5em;
	width: 100%;
`;

const Titulo = styled.h1`
	text-align: center;
	color: #f26a35;
	font-weight: var(--strong-weight);
	font-size: var(--bigger-font);
`;

const BotoesContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 0.8em;
	width: 100%;
`;

const Botao = styled.button`
	display: flex;
	padding: 0.5em 1em;
	flex-grow: 1;
	background-color: #f26a35;
	color: white;
	border-radius: var(--border-radius-s);
	border: none;
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 5em;
	font-weight: var(--regular-weight);
	font-size: var(--smaller-font);
	filter: drop-shadow(1px 1px 4px black);
`;

const TabelaWrapper = styled.div`
	width: 100%;
	height: auto;
`;

const Tabela = styled.table`
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
`;

const CorpoTabela = styled.tbody`
	width: 100%;
`;

const TituloTabela = styled.th`
	background-color: ${(props) => props.$bgColor || "transparent"};
	color: ${(props) => props.$color || "black"};
	text-align: center;
	font-weight: ${(props) => props.$weight || "var(--strong-weight)"};
	font-size: 2.5vw;
	word-wrap: break-word;
	width: 20%;
	padding: 1em 0;

	@media screen and (min-width: 600px) {
		font-size: var(--smaller-font);
	}
`;

const LinhaTabela = styled.tr`
	width: 100%;
`;

const DadoTabela = styled.td`
	background-color: ${(props) => props.$bgColor || "transparent"};
	color: ${(props) => props.$color || "black"};
	text-align: center;
	font-size: 2.5vw;
	word-wrap: break-word;
	width: 20%;
	padding: 1em 0;

	@media screen and (min-width: 600px) {
		font-size: var(--smaller-font);
	}
`;

const Logo = styled.img`
	height: auto;
	width: 6em;
`;

function Custo({ preco, tempo }) {
	return (
		<>
			<span style={{ fontSize: "var(--bigger-font)" }}>R${preco} </span>
			<span style={{ fontSize: "var(--smaller-font)" }}>{tempo}</span>
		</>
	);
}

function GerarIconeDadoTabela(tipo, quantidade) {
	if (tipo === "x") {
		return DesenharX(quantidade);
	} else if (tipo === "check") {
		return DesenharCheck(quantidade);
	} else if (tipo === "ilim") {
		return EscreverIlimitado(quantidade);
	}
}

function EscreverIlimitado(quantidade) {
	let lista = [];

	for (let i = 0; i < quantidade; i++) {
		lista.push(<DadoTabela key={i}>ILIMITADO</DadoTabela>);
	}

	return lista;
}

function DesenharX(quantidade) {
	let lista = [];

	for (let i = 0; i < quantidade; i++) {
		lista.push(
			<DadoTabela key={i}>
				<i
					style={{ color: "red", fontSize: "var(--biggest-font)" }}
					className="bi bi-x-lg"
				/>
			</DadoTabela>
		);
	}

	return lista;
}

function DesenharCheck(quantidade) {
	let lista = [];

	for (let i = 0; i < quantidade; i++) {
		lista.push(
			<DadoTabela key={i}>
				<i
					style={{ color: "green", fontSize: "var(--biggest-font)" }}
					className="bi bi-check-lg"
				/>
			</DadoTabela>
		);
	}

	return lista;
}

export default function PlanosPrecosWaychef() {
	return (
		<Container>
			<Titulo>Planos e Preços</Titulo>
			<BotoesContainer>
				<Botao>Mensal</Botao>
				<Botao>Trimestral</Botao>
				<Botao>Semestral</Botao>
				<Botao>Anual</Botao>
			</BotoesContainer>
			<TabelaWrapper>
				<Tabela>
					<CorpoTabela>
						<LinhaTabela>
							<TituloTabela $bgColor={"#f26a35"}>
								<Logo src={LogoWaychef} alt={"Logo Waychef"} />
							</TituloTabela>
							<TituloTabela $bgColor={"#f26a35"} $color={"white"}>
								GRATUITO
							</TituloTabela>
							<TituloTabela $bgColor={"#f26a35"} $color={"white"}>
								BRONZE
							</TituloTabela>
							<TituloTabela $bgColor={"#f26a35"} $color={"white"}>
								PRATA
							</TituloTabela>
							<TituloTabela $bgColor={"#f26a35"} $color={"white"}>
								OURO
							</TituloTabela>
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#f26a35"} $color={"white"}>
								Plano Mensal
							</TituloTabela>
							<DadoTabela $bgColor={"#f26a35"} $color={"white"}>
								<Custo preco={"0"} tempo={"por mês"} />
							</DadoTabela>
							<DadoTabela $bgColor={"#f26a35"} $color={"white"}>
								<Custo preco={"87,99"} tempo={"por mês"} />
							</DadoTabela>
							<DadoTabela $bgColor={"#f26a35"} $color={"white"}>
								<Custo preco={"141,99"} tempo={"por mês"} />
							</DadoTabela>
							<DadoTabela $bgColor={"#f26a35"} $color={"white"}>
								<Custo preco={"177,99"} tempo={"por mês"} />
							</DadoTabela>
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Vendas
							</TituloTabela>
							<DadoTabela>Até R$10.000 faturamento/mês</DadoTabela>
							{GerarIconeDadoTabela("ilim", 3)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Cadastro de Produtos
							</TituloTabela>
							<DadoTabela>Até 50 produtos</DadoTabela>
							{GerarIconeDadoTabela("ilim", 3)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Contas a receber
							</TituloTabela>
							<DadoTabela>Até R$5.000 por mês</DadoTabela>
							{GerarIconeDadoTabela("ilim", 3)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Contas a pagar
							</TituloTabela>
							<DadoTabela>Até R$5.000 por mês</DadoTabela>
							{GerarIconeDadoTabela("ilim", 3)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Usuários e conexões simultâneas
							</TituloTabela>
							<DadoTabela>Usuários ilimitados e 1 conexão ativa</DadoTabela>
							<DadoTabela>Usuários ilimitados e 2 conexões ativas</DadoTabela>
							<DadoTabela>Usuários ilimitados e 2 conexões ativas</DadoTabela>
							<DadoTabela>Usuários ilimitados e 2 conexões ativas</DadoTabela>
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Controle e conferência de caixa
							</TituloTabela>
							{GerarIconeDadoTabela("check", 4)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								ERP em Nuvem
							</TituloTabela>
							{GerarIconeDadoTabela("check", 4)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Relatórios e Dashboards Gerenciais
							</TituloTabela>
							{GerarIconeDadoTabela("check", 4)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Cadastro de Fornecedores
							</TituloTabela>
							{GerarIconeDadoTabela("check", 4)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Cadastro de Clientes
							</TituloTabela>
							{GerarIconeDadoTabela("check", 4)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Suporte
							</TituloTabela>
							<DadoTabela>Somente por E-mail</DadoTabela>
							<DadoTabela>Chat/E-mail</DadoTabela>
							<DadoTabela>Chat/E-mail</DadoTabela>
							<DadoTabela>Chat/E-mail</DadoTabela>
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Múltiplos locais de produção (impressoras)
							</TituloTabela>
							{GerarIconeDadoTabela("check", 4)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Controle de estoque
							</TituloTabela>
							<DadoTabela>GRATUITO</DadoTabela>
							<DadoTabela>Entrada manual</DadoTabela>
							<DadoTabela>Entrada de NFe com importação de XML</DadoTabela>
							<DadoTabela>Entrada de NFe com importação de XML</DadoTabela>
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Integração iFood
							</TituloTabela>
							{GerarIconeDadoTabela("x", 1)}
							{GerarIconeDadoTabela("check", 3)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Controle de fichas e mesas
							</TituloTabela>
							{GerarIconeDadoTabela("x", 1)}
							{GerarIconeDadoTabela("check", 3)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Promoções, combos e compostos
							</TituloTabela>
							{GerarIconeDadoTabela("x", 1)}
							{GerarIconeDadoTabela("check", 3)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Variações de produtos (tamanhos)
							</TituloTabela>
							{GerarIconeDadoTabela("x", 1)}
							{GerarIconeDadoTabela("check", 3)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Múltiplas tabelas de preços
							</TituloTabela>
							{GerarIconeDadoTabela("x", 1)}
							{GerarIconeDadoTabela("check", 3)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Emissão de NFe
							</TituloTabela>
							{GerarIconeDadoTabela("x", 1)}
							{GerarIconeDadoTabela("check", 3)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Controle de vendas a prazo
							</TituloTabela>
							{GerarIconeDadoTabela("x", 1)}
							{GerarIconeDadoTabela("check", 3)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								DRE Anual (mês a mês)
							</TituloTabela>
							{GerarIconeDadoTabela("x", 2)}
							{GerarIconeDadoTabela("check", 2)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Controle de Cartões
							</TituloTabela>
							{GerarIconeDadoTabela("x", 2)}
							{GerarIconeDadoTabela("check", 2)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Múltiplos locais de estocagem
							</TituloTabela>
							{GerarIconeDadoTabela("x", 2)}
							{GerarIconeDadoTabela("check", 2)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								Controle de vendas a prazo
							</TituloTabela>
							{GerarIconeDadoTabela("x", 3)}
							{GerarIconeDadoTabela("check", 1)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"}>
								DRE Anual (mês a mês)
							</TituloTabela>
							{GerarIconeDadoTabela("x", 3)}
							{GerarIconeDadoTabela("check", 1)}
						</LinhaTabela>

						<LinhaTabela>
							<TituloTabela $bgColor={"#EFEFEF"} $weight={"unset"} />

							<DadoTabela>
								<Container>
									<BotaoUseGratisWaychefPequeno />
								</Container>
							</DadoTabela>

							<DadoTabela>
								<Container>
									<BotaoTesteGratisWaychefPequeno />
								</Container>
							</DadoTabela>

							<DadoTabela>
								<Container>
									<BotaoTesteGratisWaychefPequeno />
								</Container>
							</DadoTabela>

							<DadoTabela>
								<Container>
									<BotaoTesteGratisWaychefPequeno />
								</Container>
							</DadoTabela>
						</LinhaTabela>
					</CorpoTabela>
				</Tabela>
			</TabelaWrapper>
		</Container>
	);
}
