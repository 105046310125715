import styled from "styled-components";
import { Link } from "react-router-dom";
// Imagens
import Banner from "../../assets/imagens/BannerGrupoSifatBranco.png";
import FundoPessoal from "../../assets/imagens/FundoAzulPessoal.webp";

const BannerContainer = styled.div`
	background-color: #1b5ea4;
	display: flex;
	flex-direction: column;
	gap: 1.5em;
	align-items: center;
	padding: 3em 1em 1em 1em;
	filter: drop-shadow(1px 1px 4px black);
	background-image: url(${FundoPessoal});
	background-position-x: ${(props) => props.$position || "center"};
	background-size: ${(props) => props.$bgsize || "cover"};
`;

const Imagem = styled.img`
	width: 13em;
	height: auto;
`;

const TextoContainer = styled.div`
	color: white;
	font-weight: var(--strong-weight);
	text-align: center;
`;

const Titulo = styled.h1`
	font-size: var(--bigger-font);
	font-weight: var(--strong-weight);
`;

const Subtitulo = styled.h2`
	font-size: var(--small-font);
	font-weight: var(--weak-weight);
`;

const PaginasWrapper = styled.div`
	display: flex;
	width: 100%;
	overflow-x: scroll;

	@media screen and (min-width: 520px) {
		overflow-x: unset;
	}
`;

const PaginasContainer = styled.div`
	margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
    align-items: baseline;
    padding-bottom: 1rem;
    justify-content: center;

	@media screen and (min-width: 520px) {
		flex-wrap: nowrap;
	}
`;

const Pagina = styled(Link)`
	text-decoration: none;
	color: white;
	font-weight: var(--regular-weight);
	font-size: var(--small-font);
	text-wrap: nowrap;
	text-align: center;
	padding: 1rem 0 0 0;
	border-bottom: ${(props) => (props.$border ? "0.3rem solid white" : "none")};

	&:hover {
		color: white;
	}

	&:link {
		color: white;
	}

	&:visited {
		color: white;
	}
`;

export default function BannerNavegacao({ pagina }) {
	let inserirBorda = [false, false, false, false, false];

	switch (pagina) {
		case "Sifat":
			inserirBorda[0] = true;
			break;
		case "Prodati":
			inserirBorda[1] = true;
			break;
		case "Waybe":
			inserirBorda[2] = true;
			break;
		case "Trabalhe conosco":
			inserirBorda[3] = true;
			break;
		case "Fale conosco":
			inserirBorda[4] = true;
			break;
		default:
			break;
	}

	return (
		<BannerContainer>
			<Imagem src={Banner} alt={"Foto equipe"} />
			<TextoContainer>
				<Titulo>Muito além de uma empresa de software.</Titulo>
				<Subtitulo>
					Conheça mais sobre o grupo que está simplificando a vida de milhares
					de empreendedores desde 1995.
				</Subtitulo>
			</TextoContainer>
			<PaginasWrapper>
				<PaginasContainer>
					<Pagina $border={inserirBorda[0]} to="/quem-somos-sifat">
						Sifat
					</Pagina>
					<Pagina $border={inserirBorda[1]} to="/quem-somos-prodati">
						Prodati
					</Pagina>
					<Pagina $border={inserirBorda[2]} to="/quem-somos">
						Waybe
					</Pagina>
					<Pagina $border={inserirBorda[3]} to="/trabalhe-conosco">
						Trabalhe Conosco
					</Pagina>
					<Pagina $border={inserirBorda[4]} to="/fale-conosco">
						Fale Conosco
					</Pagina>
				</PaginasContainer>
			</PaginasWrapper>
		</BannerContainer>
	);
}
