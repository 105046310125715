import styled from "styled-components";
import ListaClientes from "../ListaClientes";
import { Link } from "react-router-dom";

const Container = styled.div`
	color: white;
	display: flex;
	flex-direction: column;
	gap: 1.5em;
	width: 100%;

	@media screen and (min-width: 993px) {
		max-width: 800px;
	}
`;

const Titulo = styled.h1`
	text-align: left;
	font-weight: var(--regular-weight);
	font-size: var(--small-font);
`;

const CollapseContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1em;
`;

const BotaoContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	border-bottom: 2px dotted white;
	padding: 0.5em 0;
`;

const NomeBotaoContainer = styled.div`
	display: flex;
	flex-direction: row;
	gap: 0.6em;
	font-weight: var(--regular-weight);
	font-size: var(--medium-font);
	align-items: center;
	pointer-events: none;
`;

const CollapseItensContainer = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	gap: 1.3em;
`;

const CollapseItem = styled(Link)`
	font-size: var(--small-font);
	font-weight: var(--weak-weight);
	text-decoration: none;
	color: white;

	&:hover {
		color: white;
	}

	&:link {
		color: white;
	}

	&:visited {
		color: white;
	}
`;

const NomeBotao = styled.div`
	font-size: var(--small-font);
	font-weight: var(--regular-weight);
`;

const ClientesContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.3em;
`;

function alternarSeta(evt) {
	if (evt != null) {
		const pointRightClass = "bi bi-chevron-right";
		const pointDownClass = "bi bi-chevron-down";
		const clicouDiv = evt.target.tagName === "DIV";

		if (clicouDiv) {
			try {
				const estaExpandido = evt.target.ariaExpanded;

				evt.target.children[1].className =
					estaExpandido === "true" ? pointDownClass : pointRightClass;
			} catch (e) {}
		} else {
			try {
				const estaExpandido = evt.target.parentElement.ariaExpanded;

				evt.target.className =
					estaExpandido === "true" ? pointDownClass : pointRightClass;
			} catch (e) {}
		}
	}
}

export default function NossasSolucoes() {
	return (
		<Container>
			<Titulo>Conheça nossas soluções</Titulo>

			<CollapseContainer>
				<BotaoContainer
					data-bs-toggle="collapse"
					data-bs-target="#collapseFood"
					aria-expanded="false"
					aria-controls="collapseFood"
					onClick={alternarSeta}
				>
					<NomeBotaoContainer>
						<i className="bi bi-check-circle-fill text-success"></i>
						<NomeBotao>Food services</NomeBotao>
					</NomeBotaoContainer>
					<i className="bi bi-chevron-right"></i>
				</BotaoContainer>
				<CollapseItensContainer className="collapse" id="collapseFood">
					<CollapseItem to={`/waychef`}>Waychef | PDV + ERP</CollapseItem>
					<CollapseItem to={`/waymenu`}>Waymenu | Plataforma de delivery e cardápio digital</CollapseItem>
				</CollapseItensContainer>

				<BotaoContainer
					data-bs-toggle="collapse"
					data-bs-target="#collapseMercados"
					aria-expanded="false"
					aria-controls="collapseMercados"
					onClick={alternarSeta}
				>
					<NomeBotaoContainer>
						<i className="bi bi-check-circle-fill text-success"></i>
						<NomeBotao>Supermercados e casa de carnes</NomeBotao>
					</NomeBotaoContainer>
					<i className="bi bi-chevron-right"></i>
				</BotaoContainer>
				<CollapseItensContainer className="collapse" id="collapseMercados">
					<CollapseItem to={`/efex-pdv`}>Efex | PDV + ERP</CollapseItem>
				</CollapseItensContainer>

				<BotaoContainer
					data-bs-toggle="collapse"
					data-bs-target="#collapsePostos"
					aria-expanded="false"
					aria-controls="collapsePostos"
					onClick={alternarSeta}
				>
					<NomeBotaoContainer>
						<i className="bi bi-check-circle-fill text-success"></i>
						<NomeBotao>Postos de combustíveis</NomeBotao>
					</NomeBotaoContainer>
					<i className="bi bi-chevron-right"></i>
				</BotaoContainer>
				<CollapseItensContainer className="collapse" id="collapsePostos">
					<CollapseItem to={`/sifat-lumien`}>Lumien | PDV + ERP</CollapseItem>
				</CollapseItensContainer>
				
				<BotaoContainer
					data-bs-toggle="collapse"
					data-bs-target="#collapseVarejo"
					aria-expanded="false"
					aria-controls="collapseVarejo"
					onClick={alternarSeta}
				>
					<NomeBotaoContainer>
						<i className="bi bi-check-circle-fill text-success"></i>
						<NomeBotao>Varejo e lojas em geral</NomeBotao>
					</NomeBotaoContainer>
					<i className="bi bi-chevron-right"></i>
				</BotaoContainer>
				<CollapseItensContainer className="collapse" id="collapseVarejo">
					<CollapseItem to={`/sifat-flutt`}>Flutt | PDV + ERP</CollapseItem>
				</CollapseItensContainer>
			</CollapseContainer>

			<ClientesContainer>
				<Titulo>Nossos clientes</Titulo>
				<ListaClientes />
			</ClientesContainer>
		</Container>
	);
}
