import styled from "styled-components";
import {
	BotaoFaleConosco,
	BotaoReceberLigacao,
	BotaoPlanosPrecos,
	BotaoTesteGratisWaychef
} from "../Botoes";
import conteudoWaymenu from "./conteudoWaymenu";
import conteudoWaychef from "./conteudoWaychef";
import conteudoFlutt from "./conteudoFlutt";
import conteudoEfex from "./conteudoEfex";
import conteudoLumien from "./conteudoLumien";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1.5em;
	width: 100%;
`;

const Titulo = styled.h1`
	color: white;
	text-align: left;
	font-weight: var(--strong-weight);
	font-size: var(--bigger-font);
`;

const FuncoesContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	gap: 0.5em;
	width: 100%;

	@media screen and (max-width: 650px) {
		overflow-x: scroll;
	}
`;

const BotoesContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 1.2em;
	width: 100%;
	justify-content: center;
`;

const FuncaoContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	padding: 0.7em 0.9em;
	background-color: white;
	border-radius: var(--border-radius-s);
	align-items: center;
	height: 6em;
	filter: drop-shadow(1px 1px 3px black);

	@media screen and (max-width: 650px) {
		width: 50vw;
	}

	@media screen and (min-width: 651px) {
		height: 4em;
	}
`;

const PaginacaoFuncoes = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5em;
	opacity: 0.9;
	flex: 1;
`;

const ImagemFuncao = styled.img`
	width: 2.5em;
	height: auto;
`;

const TextoFuncao = styled.span`
	font-weight: var(--weak-weight);
	font-size: var(--small-font);
	color: #4e4d4d;
	text-align: center;
	width: 100%;
`;

function GerarFuncao(indexInicial, ConteudoFuncoes) {
	let funcoes = [];

	for (let i = indexInicial; i < indexInicial + 6; i++) {
		funcoes.push(
			<FuncaoContainer key={i}>
				<ImagemFuncao
					src={ConteudoFuncoes[i].Imagem}
					alt={"Imagem de " + ConteudoFuncoes[i].Alt}
				/>
				<TextoFuncao>{ConteudoFuncoes[i].Texto}</TextoFuncao>
			</FuncaoContainer>
		);
	}

	return funcoes;
}

export function FuncoesWaymenu() {
	return (
		<Container>
			<Titulo>
				No Waymenu você encontra todas as funções que o seu negócio precisa
			</Titulo>
			<FuncoesContainer id={"funcoesContainer"}>
				<PaginacaoFuncoes id={"paginacaoFuncoes1"}>
					{GerarFuncao(0, conteudoWaymenu)}
				</PaginacaoFuncoes>

				<PaginacaoFuncoes id={"paginacaoFuncoes2"}>
					{GerarFuncao(6, conteudoWaymenu)}
				</PaginacaoFuncoes>

				<PaginacaoFuncoes id={"paginacaoFuncoes3"}>
					{GerarFuncao(12, conteudoWaymenu)}
				</PaginacaoFuncoes>
			</FuncoesContainer>
			<BotoesContainer>
				<BotaoFaleConosco />
				<BotaoReceberLigacao />
				<BotaoPlanosPrecos />
			</BotoesContainer>
		</Container>
	);
}

export function FuncoesWaychef() {
	return (
		<Container>
			<Titulo>
				No Waychef você encontra todas as funções que o seu negócio precisa
			</Titulo>
			<FuncoesContainer id={"funcoesContainer"}>
				<PaginacaoFuncoes id={"paginacaoFuncoes1"}>
					{GerarFuncao(0, conteudoWaychef)}
				</PaginacaoFuncoes>

				<PaginacaoFuncoes id={"paginacaoFuncoes2"}>
					{GerarFuncao(6, conteudoWaychef)}
				</PaginacaoFuncoes>

				<PaginacaoFuncoes id={"paginacaoFuncoes3"}>
					{GerarFuncao(12, conteudoWaychef)}
				</PaginacaoFuncoes>
			</FuncoesContainer>
			<BotoesContainer>
				<BotaoFaleConosco />
				<BotaoReceberLigacao />
				<BotaoTesteGratisWaychef />
			</BotoesContainer>
		</Container>
	);
}

export function FuncoesFlutt() {
	return (
		<Container>
			<Titulo>
				A ferramenta indispensável para o sucesso do seu negócio varejista!
			</Titulo>
			<FuncoesContainer id={"funcoesContainer"}>
				<PaginacaoFuncoes id={"paginacaoFuncoes1"}>
					{GerarFuncao(0, conteudoFlutt)}
				</PaginacaoFuncoes>

				<PaginacaoFuncoes id={"paginacaoFuncoes2"}>
					{GerarFuncao(6, conteudoFlutt)}
				</PaginacaoFuncoes>

				<PaginacaoFuncoes id={"paginacaoFuncoes3"}>
					{GerarFuncao(12, conteudoFlutt)}
				</PaginacaoFuncoes>
			</FuncoesContainer>
			<BotoesContainer>
				<BotaoFaleConosco />
				<BotaoReceberLigacao />
			</BotoesContainer>
		</Container>
	);
}

export function FuncoesEfex() {
	return (
		<Container>
			<Titulo>
				No Efex você encontra todas as funções que o seu mercado precisa
			</Titulo>
			<FuncoesContainer id={"funcoesContainer"}>
				<PaginacaoFuncoes id={"paginacaoFuncoes1"}>
					{GerarFuncao(0, conteudoEfex)}
				</PaginacaoFuncoes>

				<PaginacaoFuncoes id={"paginacaoFuncoes2"}>
					{GerarFuncao(6, conteudoEfex)}
				</PaginacaoFuncoes>

				<PaginacaoFuncoes id={"paginacaoFuncoes3"}>
					{GerarFuncao(12, conteudoEfex)}
				</PaginacaoFuncoes>
			</FuncoesContainer>
			<BotoesContainer>
				<BotaoFaleConosco />
				<BotaoReceberLigacao />
			</BotoesContainer>
		</Container>
	);
}

export function FuncoesLumien() {
	return (
		<Container>
			<Titulo>
				O Lumien oferece todas as funções necessárias para o seu negócio
			</Titulo>
			<FuncoesContainer id={"funcoesContainer"}>
				<PaginacaoFuncoes id={"paginacaoFuncoes1"}>
					{GerarFuncao(0, conteudoLumien)}
				</PaginacaoFuncoes>

				<PaginacaoFuncoes id={"paginacaoFuncoes2"}>
					{GerarFuncao(6, conteudoLumien)}
				</PaginacaoFuncoes>

				<PaginacaoFuncoes id={"paginacaoFuncoes3"}>
					{GerarFuncao(12, conteudoLumien)}
				</PaginacaoFuncoes>
			</FuncoesContainer>
			<BotoesContainer>
				<BotaoFaleConosco />
				<BotaoReceberLigacao />
			</BotoesContainer>
		</Container>
	);
}