import styled from "styled-components";
import CardQualidade from "../CardQualidade";
// Imagens das qualidades
import Monitor from "../../assets/imagens/imagensQualidades/monitor.svg";
import Mao from "../../assets/imagens/imagensQualidades/mao.svg";
import Quebracabeca from "../../assets/imagens/imagensQualidades/quebracabeca.svg";
import Brasil from "../../assets/imagens/imagensQualidades/brasil.svg";
import Carteira from "../../assets/imagens/imagensQualidades/carteira.svg";
import Teia from "../../assets/imagens/imagensQualidades/teia.svg";
import MulherComNotebook from "../../assets/imagens/MulherComNotebook.webp";
import MonitorWayservice from "../../assets/imagens/MonitorWayservice.webp";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2em;
	width: 100%;

	@media screen and (min-width: 993px) {
		background-image: url(${(props) => props.$bgimage || ""});
		background-position-x: right;
		background-position-y: bottom;
		background-repeat: no-repeat;
		background-size: 43%;
	}
`;

const ContainerTexto = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2em;

	@media screen and (min-width: 993px) {
		width: 65%;
		padding-bottom: 4em;
	}
`;

const Titulo = styled.h1`
	color: #1b355e;
	font-size: var(--bigger-font);
	font-weight: var(--strong-weight);
	text-align: left;
`;

const CardsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1em;

	@media screen and (min-width: 650px) {
		flex-direction: row;
		flex-wrap: wrap;
		align-items: stretch;
		justify-content: space-around;
	}
`;

const Imagem = styled.img`
	display: none;

	@media screen and (min-width: 993px) {
		display: block;
	}
`;

export function QualidadesSifat() {
	return (
		<>
			<Container $bgimage={MulherComNotebook}>
				<ContainerTexto>
					<Titulo>
						Nosso compromisso é fornecer as ferramentas para você encontrar o
						seu sucesso.
					</Titulo>
					<CardsContainer>
						<CardQualidade
							imagem={Monitor}
							titulo={"Plataformas Web ou Desktop"}
							paragrafo={
								"Gerencie seu empreendimento por meio do aplicativo instalado no computador ou de qualquer lugar, através das plataformas online."
							}
						/>
						<CardQualidade
							imagem={Mao}
							titulo={"Variedade de Segmentos Atendidos"}
							paragrafo={
								"As ferramentas da Sifat atendem diversos segmentos, tais como: Food Services, Supermercados e similares, Lojas e Empreendimentos Varejistas em Geral, e Postos de Combustíveis."
							}
						/>
						<CardQualidade
							imagem={Quebracabeca}
							titulo={"Integrado com as Principais Plataformas de Vendas"}
							paragrafo={
								"Na Sifat, garantimos que nossos clientes estejam sempre faturando. Nossas soluções são integradas com diversas plataformas de vendas, incluindo iFood, Wabiz, SiteMercado, Waymenu, entre outras."
							}
						/>
						<CardQualidade
							imagem={Brasil}
							titulo={"Atende Todo o Território Brasileiro"}
							paragrafo={
								"Atendemos em todo o Território Nacional, oferecendo excelência em tecnologia e inovação."
							}
						/>
						<CardQualidade
							imagem={Carteira}
							titulo={" Diversas Formas de Pagamento"}
							paragrafo={
								"Buscamos facilitar sua experiência ao adquirir nossas soluções tecnológicas, oferecendo diversas alternativas de pagamento."
							}
						/>
						<CardQualidade
							imagem={Teia}
							titulo={"Suporte"}
							paragrafo={
								"Na Sifat, disponibilizamos canais de suporte para nossos clientes, incluindo e-mail, telefone, WhatsApp e plantão. Entre em contato com nossos Consultores Comerciais para mais informações sobre os serviços de suporte."
							}
						/>
					</CardsContainer>
				</ContainerTexto>
			</Container>
		</>
	);
}

export function QualidadesWaybe() {
	return (
		<>
			<Container>
				<Titulo>
					Nosso compromisso é inovar as nossas soluções para melhorar sua
					gestão.
				</Titulo>
				<CardsContainer>
					<CardQualidade
						imagem={Monitor}
						titulo={"Plataformas Web ou Desktop"}
						paragrafo={
							"Gerencie seu empreendimento por meio do aplicativo instalado no computador ou de qualquer lugar, através das plataformas online."
						}
						$width={"23%"}
					/>
					<CardQualidade
						imagem={Mao}
						titulo={"Variedade de Segmentos Atendidos"}
						paragrafo={
							"As ferramentas da Sifat atendem diversos segmentos, tais como: Food Services, Supermercados e similares, Lojas e Empreendimentos Varejistas em Geral, e Postos de Combustíveis."
						}
						$width={"23%"}
					/>
					<CardQualidade
						imagem={Carteira}
						titulo={"Diversas Formas de Pagamento"}
						paragrafo={
							"Buscamos facilitar sua experiência ao adquirir nossas soluções tecnológicas, oferecendo diversas alternativas de pagamento."
						}
						$width={"23%"}
					/>
					<CardQualidade
						imagem={Teia}
						titulo={"Suporte"}
						paragrafo={
							"Na Sifat, disponibilizamos canais de suporte para nossos clientes, incluindo e-mail, telefone, WhatsApp e plantão. Entre em contato com nossos Consultores Comerciais para mais informações sobre os serviços de suporte."
						}
						$width={"23%"}
					/>
				</CardsContainer>
				<Imagem src={MonitorWayservice} />
			</Container>
		</>
	);
}
