import styled from "styled-components";
import { useEffect } from "react";
// Componentes personalizados
import BlocoTituloTextoBotaoImagem from "../../components/BlocoTituloTextoBotaoImagem";
import { BotaoFaleConosco, BotaoReceberLigacao } from "../../components/Botoes";
import { PorqueProdutoWayservice } from "../../components/PorqueProduto";
import { VideoDemonstrativoWayservice } from "../../components/VideoDemonstrativo";
import { TituloFeaturesTextoWayservice } from "../../components/TituloFeaturesTexto";
import { DuvidasWayservice } from "../../components/Duvidas";
import Form from "../../components/Form";
import SecaoPagina from "../../components/SecaoPagina";
import PageLayout from "../../layouts/PageLayout";
// Imagens
import Header from "../../assets/imagens/wayservice/header.webp";
import FundoVideo from "../../assets/imagens/wayservice/fundo_demo.webp";
import FundoPerguntas from "../../assets/imagens/wayservice/fundo_perguntas.webp";
import TelasAplicacao from "../../assets/imagens/wayservice/produto.webp";
import LogoProduto from "../../assets/imagens/wayservice/Logotipo Wayservice - Branco.svg";

const Logo = styled.img`
	width: 10em;
	height: auto;

	@media screen and (min-width: 993px) {
		margin-right: auto;
	}
`;

export default function PageWayservice() {
	useEffect(() => {
		require("bootstrap/dist/js/bootstrap.min.js");
	}, []);

	return (
		<PageLayout>
			<SecaoPagina
				$paddingmin={"2em"}
				$bgimagem={Header}
				$position={"left"}
			>
				<Logo src={LogoProduto} alt={"Logo Waypay"} />

				<BlocoTituloTextoBotaoImagem
					imagem={TelasAplicacao}
					max={"30em"}
					margemImagem={null}
					corTitulo={null}
					paragrafo2={null}
					tituloTipoA={null}
					titulo2={null}
					possuiLogosClientes={false}
					botao1={<BotaoFaleConosco />}
					botao2={<BotaoReceberLigacao />}
					corParagrafo={"white"}
					paragrafo1={
						"Dê autonomia para o seu cliente criar comandas e efetuar pagamentos em segundos!"
					}
					tituloTipoB={true}
					titulo1={
						"Aumente seu ticket médio com o autoatendimento Wayservice"
					}
				/>
			</SecaoPagina>

			<SecaoPagina $bgcolor={"#EFEFEF"} $paddingmin={"2em"}>
				<TituloFeaturesTextoWayservice />
			</SecaoPagina>

			<SecaoPagina $bgcolor={"#EFEFEF"} $paddingmin={"2em"}>
				<PorqueProdutoWayservice />
			</SecaoPagina>

			<SecaoPagina $bgimagem={FundoVideo} $bgcolor={"#EFEFEF"} $paddingmin={"2em"}>
				<VideoDemonstrativoWayservice />
			</SecaoPagina>

			<SecaoPagina $bgimagem={FundoPerguntas} $paddingmin={"2em"}>
				<DuvidasWayservice />
			</SecaoPagina>

			<SecaoPagina
				$bg={"#EFEFEF"}
				$paddingmin={"2em 2em 9em"}
				$paddingmid={"2em 2em 9em"}
				$paddingmax={"4em 4em 8em"}
				id={"formLigacao"}
			>
				<Form
					formProduto={true}
					titulo={"Nosso time liga para você!"}
					subtitulo={
						"Pronto para começar? Preencha o formulário abaixo e o nosso time de consultores entrará em contato com você."
					}
					trabalheConosco={false}
					color={"black"}
					$bgcolor={"#ED6A37"}
				/>
			</SecaoPagina>
		</PageLayout>
	);
}
