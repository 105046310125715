import styled from "styled-components";
import { useEffect } from "react";
// Componentes personalizados
import BlocoTituloTextoBotaoImagem from "../../components/BlocoTituloTextoBotaoImagem";
import { BotaoFaleConosco, BotaoReceberLigacao } from "../../components/Botoes";
import { PorqueProdutoEfex } from "../../components/PorqueProduto";
import { FuncoesEfex } from "../../components/Funcoes";
import { TituloFeaturesTextoEfex } from "../../components/TituloFeaturesTexto";
import { DuvidasEfex } from "../../components/Duvidas";
import Form from "../../components/Form";
import SecaoPagina from "../../components/SecaoPagina";
import PageLayout from "../../layouts/PageLayout";
// Imagens
import FundoHeader from "../../assets/imagens/efex/header.webp";
import FundoFuncoes from "../../assets/imagens/efex/fundo-funcoes.webp";
import FundoPerguntas from "../../assets/imagens/efex/fundo-perguntas.webp";
import TelasAplicacao from "../../assets/imagens/efex/Homepage.webp";
import LogoProduto from "../../assets/imagens/logos-produtos/Efex - Branco.svg";

const Logo = styled.img`
	width: 4em;
	height: auto;

	@media screen and (min-width: 993px) {
		margin-right: auto;
	}
`;

export default function PageEfex() {
	useEffect(() => {
		require("bootstrap/dist/js/bootstrap.min.js");
	}, []);

	return (
		<PageLayout>
			<SecaoPagina
				$paddingmin={"2em"}
				$bgimagem={FundoHeader}
				$position={"left"}
			>
				<Logo src={LogoProduto} alt={"Logo Efex"} />

				<BlocoTituloTextoBotaoImagem
					imagem={TelasAplicacao}
					max={"30em"}
					margemImagem={null}
					corTitulo={null}
					paragrafo2={null}
					tituloTipoA={null}
					titulo2={null}
					possuiLogosClientes={false}
					botao1={<BotaoFaleConosco />}
					botao2={<BotaoReceberLigacao />}
					corParagrafo={"white"}
					paragrafo1={
						"Gerencie o seu negócio com mais eficiência e agilidade! O EFEX é um PDV completo, que realiza vendas e emite documentos fiscais com poucos cliques."
					}
					tituloTipoB={true}
					titulo1={
						"PDV + ERP completo para mercados e casa de carnes"
					}
				/>
			</SecaoPagina>

			<SecaoPagina $bgcolor={"#EFEFEF"} $paddingmin={"2em"}>
				<TituloFeaturesTextoEfex />
			</SecaoPagina>

			<SecaoPagina $bgcolor={"#EFEFEF"} $paddingmin={"2em"}>
				<PorqueProdutoEfex />
			</SecaoPagina>

			<SecaoPagina $bgimagem={FundoFuncoes} $paddingmin={"2em"}>
				<FuncoesEfex />
			</SecaoPagina>

			<SecaoPagina $bgimagem={FundoPerguntas} $paddingmin={"2em"}>
				<DuvidasEfex />
			</SecaoPagina>

			<SecaoPagina
				$bg={"#EFEFEF"}
				$paddingmin={"2em 2em 9em"}
				$paddingmid={"2em 2em 9em"}
				$paddingmax={"4em 4em 8em"}
				id={"formLigacao"}
			>
				<Form
					formProduto={true}
					titulo={"Nosso time liga para você!"}
					subtitulo={
						"Pronto para começar? Preencha o formulário abaixo e o nosso time de consultores entrará em contato com você."
					}
					trabalheConosco={false}
					color={"black"}
					$bgcolor={"#ee0000"}
				/>
			</SecaoPagina>
		</PageLayout>
	);
}
