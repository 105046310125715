import styled from "styled-components";

const Titulo = styled.h1`
	text-align: center;
	color: ${(props) => props.$cor || "white"};
	font-weight: var(--strong-weight);
	font-size: var(--biggest-font);
`;

const Subtitulo = styled.h2`
	text-align: center;
    color: ${(props) => props.$cor || "white"};
	font-weight: var(--medium-weight);
	font-size: var(--big-font);
`;

const VideoContainer = styled.div`
    padding: 75% 0 0 0;
    position: relative;
    width: 100%;

    @media screen and (max-width: 900px) {
		min-height: 35em;
	}    
`;

const Frame = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export function VideoDemonstrativoWaypay() {
	return (
    <>
        <Titulo>Conheça o autopagamento Waypay</Titulo>
        <Subtitulo>Acompanhe a demonstração do autopagamento em um de nossos clientes</Subtitulo>

        <VideoContainer>
            <Frame src="https://player.vimeo.com/video/1019430075?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="copy_09BE149E-D6D9-46BB-87DE-43C9433892E7">
            </Frame>
        </VideoContainer>
    </>
    );
}

export function VideoDemonstrativoWayservice() {
	return (
    <>
        <Titulo>Conheça o autoatendimento Wayservice</Titulo>
        <Subtitulo>Acompanhe a demonstração do cardápio de autoatendimento em um de nossos clientes</Subtitulo>

        <VideoContainer>
            <Frame src="https://player.vimeo.com/video/1018295284?h=577b820063" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" title="copy_09BE149E-D6D9-46BB-87DE-43C9433892E7">
            </Frame>
        </VideoContainer>
    </>
    );
}

//width="640" height="360" frameborder="0"    allowfullscreen></iframe>