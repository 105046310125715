import styled from "styled-components";
import { useEffect } from "react";
// Componentes personalizados
import BlocoTituloTextoBotaoImagem from "../../components/BlocoTituloTextoBotaoImagem";
import { BotaoFaleConosco, BotaoReceberLigacao } from "../../components/Botoes";
import { PorqueProdutoWaymenu } from "../../components/PorqueProduto";
import { FuncoesWaymenu } from "../../components/Funcoes";
import { TituloFeaturesTextoWaymenu } from "../../components/TituloFeaturesTexto";
import { DuvidasWaymenu } from "../../components/Duvidas";
import Form from "../../components/Form";
import SecaoPagina from "../../components/SecaoPagina";
import PageLayout from "../../layouts/PageLayout";
import PlanosPrecosWaymenu from "../../components/PlanosPrecosWaymenu";
// Imagens
import Header from "../../assets/imagens/waymenu/Header.webp";
import FundoFuncoes from "../../assets/imagens/waymenu/FundoFuncoes.webp";
import PerguntasWaymenu from "../../assets/imagens/waymenu/PerguntasWaymenu.webp";
import TelasAplicacao from "../../assets/imagens/waymenu/TelasAplicacao.webp";
import LogoProduto from "../../assets/imagens/logos-produtos/Waymenu - Branco.svg";

const Logo = styled.img`
	width: 10em;
	height: auto;

	@media screen and (min-width: 993px) {
		margin-right: auto;
	}
`;

export default function PageWaymenu() {
	useEffect(() => {
		require("bootstrap/dist/js/bootstrap.min.js");
	}, []);

	return (
		<PageLayout>
			<SecaoPagina
				$paddingmin={"2em"}
				$bgimagem={Header}
				$position={"left"}
			>
				<Logo src={LogoProduto} alt={"Logo WayMenu"} />

				<BlocoTituloTextoBotaoImagem
					imagem={TelasAplicacao}
					max={"30em"}
					margemImagem={null}
					corTitulo={null}
					paragrafo2={null}
					tituloTipoA={null}
					titulo2={null}
					possuiLogosClientes={false}
					botao1={<BotaoFaleConosco />}
					botao2={<BotaoReceberLigacao />}
					corParagrafo={"white"}
					paragrafo1={
						"Crie seu site de cardápio personalizado, automatize suas vendas, imprima pedidos e fidelize seus clientes!"
					}
					tituloTipoB={true}
					titulo1={
						"O Waymenu é o cardápio digital e delivery grátis para Food Services"
					}
				/>
			</SecaoPagina>

			<SecaoPagina $bgcolor={"#EFEFEF"} $paddingmin={"2em"}>
				<TituloFeaturesTextoWaymenu />
			</SecaoPagina>

			<SecaoPagina $bgcolor={"#EFEFEF"} $paddingmin={"2em"}>
				<PorqueProdutoWaymenu />
			</SecaoPagina>

			<SecaoPagina $bgimagem={FundoFuncoes} $paddingmin={"2em"}>
				<FuncoesWaymenu />
			</SecaoPagina>

			<SecaoPagina $bgimagem={PerguntasWaymenu} $paddingmin={"2em"}>
				<DuvidasWaymenu />
			</SecaoPagina>

			<SecaoPagina
				$bgcolor={"#EFEFEF"}
				$paddingmin={"2em 0.4em"}
				$paddingmid={"2em 1em"}
				id={"tabela-precos"}
			>
				<PlanosPrecosWaymenu />
			</SecaoPagina>

			<SecaoPagina
				$bg={"#EFEFEF"}
				$paddingmin={"2em 2em 9em"}
				$paddingmid={"2em 2em 9em"}
				$paddingmax={"4em 4em 8em"}
				id={"formLigacao"}
			>
				<Form
					formProduto={true}
					titulo={"Nosso time liga para você!"}
					subtitulo={
						"Pronto para começar? Preencha o formulário abaixo e o nosso time de consultores entrará em contato com você."
					}
					trabalheConosco={false}
					color={"black"}
					$bgcolor={"#f09400"}
				/>
			</SecaoPagina>
		</PageLayout>
	);
}
