import BotaoModelo from "../BotaoModelo";

export function BotaoFaleConosco({ $grow }) {
	return (
		<BotaoModelo
			$backgroundcolor={"#88bd28"}
			texto={"Fale Conosco"}
			temIcone={"true"}
			$grow={$grow}
			hrefUrl={"https://api.whatsapp.com/send?l=pt_br&phone=5517996349340"}
			customOnClick={(url) => {
				window.location.href = url;
			}}
		/>
	);
}

export function BotaoEnviar() {
	return (
		<BotaoModelo
			texto={"Enviar"}
			$backgroundcolor={"#88bd28"}
			$width={"fit-content"}
			$alignself={"center"}
		/>
	);
}

export function BotaoAreaCliente() {
	return (
		<BotaoModelo 
			$backgroundcolor={"#1B5EA4"} 
			texto={"Área do cliente"} 
			hrefUrl={"http://cliente.sifat.com.br/login"}
			customOnClick={(url) => {
				window.location.href = url;
			}}
		/>
	);
}

export function BotaoSejaParceiro() {
	return (
		<BotaoModelo
			$backgroundcolor={"#1B5EA4"}
			texto={"Seja um parceiro"}
			hrefUrl={null}
			customOnClick={() => {
				const SecaoPagina = document.getElementById("formParceiro");
				console.log(SecaoPagina);
				if (SecaoPagina) {
					SecaoPagina.scrollIntoView({ behavior: "smooth" });
				}
			}}
		/>
	);
}

export function BotaoEnviarMensagem() {
	return (
		<BotaoModelo
			$backgroundcolor={"#1B5EA4"}
			texto={"Enviar mensagem"}
			hrefUrl={null}
			customOnClick={() => {
				const SecaoPagina = document.getElementById("formFale");
				console.log(SecaoPagina);
				if (SecaoPagina) {
					SecaoPagina.scrollIntoView({ behavior: "smooth" });
				}
			}}
		/>
	);
}

export function BotaoQueroSerParceiro() {
	return (
		<BotaoModelo
			$backgroundcolor={"white"}
			color={"#1B355E"}
			texto={"Quero ser um parceiro Sifat"}
			$width={"fit-content"}
			$alignself={"center"}
			$padding={"2em 2em"}
			$bigradius={"var(--border-radius-l)"}
			customOnClick={() => {
				const SecaoPagina = document.getElementById("formLigacao");
				if (SecaoPagina) {
					SecaoPagina.scrollIntoView({ behavior: "smooth" });
				}
			}}
			hrefUrl={null}
		/>
	);
}

export function BotaoReceberLigacao() {
	return (
		<BotaoModelo
			$backgroundcolor={"#1B5EA4"}
			texto={"Receber ligação"}
			customOnClick={() => {
				const SecaoPagina = document.getElementById("formLigacao");
				if (SecaoPagina) {
					SecaoPagina.scrollIntoView({ behavior: "smooth" });
				}
			}}
			hrefUrl={null}
		/>
	);
}

export function BotaoTesteGratisWaychef() {
	return (
		<BotaoModelo 
			$backgroundcolor={"#1B5EA4"} 
			texto={"Teste grátis"}
			hrefUrl={"https://erp.waychef.com.br/publico/cadastro_empresa_integrador.xhtml?integrador=2"}
			customOnClick={(url) => {
				window.location.href = url;
			}}
		/>
	);
}

export function BotaoTesteGratisWaychefPequeno() {
	return (
		<BotaoModelo 
			$backgroundcolor={"#1B5EA4"} 
			texto={"Teste grátis"}
			hrefUrl={"https://erp.waychef.com.br/publico/cadastro_empresa_integrador.xhtml?integrador=2"}
			$padding={"1.5em 0.8em"}
			customOnClick={(url) => {
				window.location.href = url;
			}}
		/>
	);
}

export function BotaoUseGratisWaychef() {
	return (
		<BotaoModelo 
			$backgroundcolor={"#1B5EA4"} 
			texto={"Use grátis"}
			hrefUrl={"https://erp.waychef.com.br/publico/cadastro_empresa_integrador.xhtml?integrador=2"}
			customOnClick={(url) => {
				window.location.href = url;
			}}
		/>
	);
}

export function BotaoUseGratisWaychefPequeno() {
	return (
		<BotaoModelo 
			$backgroundcolor={"#1B5EA4"} 
			texto={"Use grátis"}
			$padding={"1.5em 0.8em"}
			hrefUrl={"https://erp.waychef.com.br/publico/cadastro_empresa_integrador.xhtml?integrador=2"}
			customOnClick={(url) => {
				window.location.href = url;
			}}
		/>
	);
}

export function BotaoLojaTesteWaymenu() {
	return (
		<BotaoModelo 
			$backgroundcolor={"#1B5EA4"} 
			texto={"Loja teste"}
			hrefUrl={"https://waymenu.com.br/menu/wayburger/delivery/"}
			customOnClick={(url) => {
				window.location.href = url;
			}}
		/>
	);
}

export function BotaoInscrevaWaymenu() {
	return (
		<BotaoModelo 
			$backgroundcolor={"#1B5EA4"} 
			texto={"Inscreva-se"}
			hrefUrl={"https://waymenu.com.br/plataforma/novo/"}
			customOnClick={(url) => {
				window.location.href = url;
			}}
		/>
	);
}

export function BotaoPlanosPrecos() {
	return <BotaoModelo 
		$backgroundcolor={"#1B5EA4"} 
		texto={"Planos e Preços"}
		hrefUrl={null}
			customOnClick={() => {
				const SecaoPagina = document.getElementById("tabela-precos");
				console.log(SecaoPagina);
				if (SecaoPagina) {
					SecaoPagina.scrollIntoView({ behavior: "smooth" });
				}
			}}
	/>;
}

export function BotaoEnviarCurriculo() {
	return (
		<BotaoModelo
			$backgroundcolor={"#1B5EA4"}
			texto={"Enviar currículo"}
			$grow={"0"}
			$width={"fit-content"}
			$alignself={"center"}
			hrefUrl={null}
			customOnClick={() => {
				const SecaoPagina = document.getElementById("formTrabalhe");
				console.log(SecaoPagina);
				if (SecaoPagina) {
					SecaoPagina.scrollIntoView({ behavior: "smooth" });
				}
			}}
		/>
	);
}
